import app from '../app';
import { closeModal, nonceGenerator, openModal, s3_ProcessPaxInfo } from '../utils';

app.controller('PaymentController', function ($scope, $rootScope, $location, RESOURCES, COUNTRIES, __ENV, globalData, $sce, PaymentService, bookingService, eTapServices, md5, $translate) {
  init();

  function init() {
    $scope.countries = COUNTRIES;
    $scope.billingAddress = {};
    $scope.paxNames = globalData.getPassengers();

    $scope.pnr = globalData.getElgBkgData().tnr.tnr_ers_pnr;
    $scope.etap_Ticket_type = 0;

    $translate('Text328').then(function (txtMsg) {
      $scope.btnCaption1 = txtMsg;
    });
    $translate('Text329').then(function (txtMsg) {
      $scope.btnCaption2 = txtMsg;
    });

    $translate('Text331').then(function (txtMsg) {
      $scope.eTapTicketMsg = txtMsg;
    });

    $scope.showEtapTicketing = false;
    var payment = {};
    $scope.partPay = false;

    globalData.setPayment(payment);
    $scope.paymentMsg = '';
    $scope.tktError = '';
    $scope.tktSuccess = false;
    $scope.tktSuccessMsg = '';
    $scope.bkgType = globalData.getbookingType();
    $scope.isVoucherOnlyBkg = false;
    $scope.currency = globalData.getCurrency();
    $scope.currencySign = globalData.getCurrencySign($scope.currency);
    $scope.vouchers = [];
    $scope.voucherAmount = 0.00;
    $scope.voucherNumber = 0;
    $rootScope.voucherNumber = 0;

    if ($scope.currency === 'GBP') {
      $scope.voucherUnitValue = parseFloat(RESOURCES.VOUCHER_UNIT_VALUE.GBP);
    } else if ($scope.currency === 'EUR') {
      $scope.voucherUnitValue = parseFloat(RESOURCES.VOUCHER_UNIT_VALUE.EUR);
    }

    if ($scope.bkgType === 'B' || $scope.bkgType === 'M' || $scope.bkgType === 'I' || IsPALSBeyond() === true) {
      init_Voucher();
    } else {
      init_CardPayment();
    }
    $rootScope.showVoucherMsg = false;
  }

  function IsPALSBeyond() {
    var result = false;
    if ($rootScope.loggedInUser.ARIA_User.Staff_Voucher === 'true') {
      if ($scope.bkgType === 'P') {
        var bkgSale = globalData.getBookingSaleData();
        for (var i = 0; i < bkgSale.segments.length; i++) {
          if (bkgSale.segments[i].carrier === 'SN') {
            result = true;
            break;
          }
        }
      }
    }

    return result;
  }

  function init_Voucher() {
    //  Initialise Voucher payment
    // console.log("PaymentController - init_Voucher");
    $scope.vouchers = [];
    $scope.vchrLowBalance = false;
    $scope.vchrLowBalanceErrMsg = '';

    if ($scope.bkgType === 'B' || IsPALSBeyond() === true) {
      $scope.showStaffVoucher = true;
      if (typeof ($rootScope.loggedInUser.ARIA_User.ESV_remaining) !== 'undefined') {
        //if($rootScope.loggedInUser.ARIA_User.ESV_remaining > ){
        $scope.nonUKEmployee = true;
        $scope.maxESVouchers = $rootScope.loggedInUser.ARIA_User.ESV_remaining;
      } else {
        $scope.nonUKEmployee = false;
      }
    } else {
      $scope.showStaffVoucher = false;
    }

    if ($scope.bkgType === 'B') {
      //$scope.availVouchersAmt = parseFloat($rootScope.loggedInUser.ARIA_User.Staff_Voucher_Current_Balance).toFixed(2);
    } else if ($scope.bkgType === 'M') {
      $scope.availVouchersAmt = parseFloat($rootScope.loggedInUser.ARIA_User.Momentum_Voucher_Current_Balance).toFixed(2);
    } else if ($scope.bkgType === 'I') {
      $scope.availVouchersAmt = parseFloat($rootScope.loggedInUser.ARIA_User.Mitie_Voucher_Current_Balance).toFixed(2);
    } else if (IsPALSBeyond() === true) {
      $scope.availVouchersAmt = parseFloat($rootScope.loggedInUser.ARIA_User.Staff_Voucher_Current_Balance).toFixed(2);
    }

    if ($scope.bkgType === 'B') {
      /*if (IsAnyBelgian() === true){

        $scope.voucherAmount = getAnyBelgianMaxValue();

        if ($scope.availVouchersAmt < $scope.voucherAmount) {
          $scope.voucherAmount = $scope.availVouchersAmt;
        }
        $scope.voucherAmount = parseFloat($scope.voucherAmount).toFixed(2);
      }
      else {
        if (getAmount() < $scope.availVouchersAmt){
          $scope.voucherAmount = getAmount().toFixed(2);
        }
        else {
          $scope.voucherAmount  = parseFloat($scope.availVouchersAmt).toFixed(2);
        }
      }*/

      $scope.voucherNumber = getMinStaffVouchersReqd();
      $scope.voucherAmount = ($scope.voucherNumber * $scope.voucherUnitValue);
      // console.log("voucher number: " + $scope.voucherNumber);
      // console.log("voucher Amount: " + $scope.voucherAmount);
      $scope.hideInc = false;
      $scope.PalsVchrPay = false;
    } else if (IsPALSBeyond() === true) {
      $scope.voucherAmount = 0.00;
      $scope.voucherNumber = 0;
      $scope.hideInc = false;
      $scope.PalsVchrPay = true;
    } else {
      $scope.voucherAmount = getAmount().toFixed(2);
      $scope.hideInc = true;
      $scope.PalsVchrPay = false;

      // console.log("Avail = " + $scope.availVouchersAmt);
      // console.log("Booking Value = " + $scope.voucherAmount);

      if (Number($scope.availVouchersAmt) < Number($scope.voucherAmount)) {
        $scope.vchrLowBalance = true;
        $translate('Text312').then(function (txtMsg) {
          $scope.vchrLowBalanceErrMsg = txtMsg; //"Insufficient vouchers balance.";
        });
      }
    }

    // $scope.voucherAmount = ($scope.voucherUnitValue * globalData.getBookingSaleData().names.length);
    // console.log($scope.voucherAmount);
    if ($scope.bkgType === 'B') {
      $scope.balancePayment = getESVBalance();
    } else if (IsPALSBeyond() === true) {
      $scope.balancePayment = getPalsBalance();
    } else {
      $scope.balancePayment = (getAmount() - $scope.voucherAmount);
    }

    if ($scope.balancePayment > 0) {
      $scope.isAmountBalance = true;
    } else {
      $scope.isAmountBalance = false;
    }

    $scope.showEdit = true; // to hide the card capture iframe
    $scope.showBilingAddress = false;
    //$scope.showESV = true;
    //********************************* */
    if ($scope.nonUKEmployee === true) {
      if ($rootScope.loggedInUser.ARIA_User.ESV_remaining > 0) {
        $scope.showESV = true;
      } else {
        $scope.showESV = false;
        init_CardPayment();
      }
    } else {
      $scope.showESV = true;
    }

    // console.log("Init Voucher booking type = " + $scope.bkgType);

  }

  function init_CardPayment() {
    // console.log("PaymentController - init_CardPayment");
    // console.log("Vouchers in the booking:");
    // console.log($scope.vouchers);
    openModal(); // dc_setup will closeModal() as the hourglass will be closed only after the response.
    $scope.cardCaptureSuccess = false;
    var amount = 0;
    var reference = getMerchantReference();
    if ($scope.partPay === true) {
      amount = $scope.balancePayment;
    } else {
      amount = getAmount();
    }

    $scope.amount = amount;

    $scope.isVoucherOnlyBkg = false; // As booking involves card payment too.
    $scope.currency = globalData.getCurrency();
    $scope.currencySign = globalData.getCurrencySign($scope.currency);
    $scope.phone = globalData.getBookingSaleData().contact_details.phone[0];
    $scope.billingAddress = globalData.getBookingSaleData().contact_details.address;

    if (($scope.billingAddress.line_1 === undefined) || ($scope.billingAddress.line_2 === undefined) || ($scope.billingAddress.postcode === undefined) || ($scope.billingAddress.country === undefined) || ($scope.phone === undefined) || ($scope.billingAddress.line_1 === '') || ($scope.billingAddress.line_2 === '') || ($scope.billingAddress.country === '') || ($scope.phone === '') || ($scope.billingAddress.postcode === '')) {
      $scope.showEdit = true;
      closeModal();
    } else {

      $scope.showEdit = false;
      dc_setup(reference, amount, $scope.currency);
    }
    $scope.showBilingAddress = true;
    $scope.showESV = false;
  }

  function IsAnyBelgian() {
    //  console.log("Is Any Belgian");

    var result = false;
    var bkgSegs = globalData.getBookingSegments();

    for (var i = 0; i < bkgSegs.length; i++) {
      if (bkgSegs[i].isABS === true) {
        result = true;
        break;
      }
    }
    return result;
  }

  // function getAnyBelgianMaxValue() {
  //   //  console.log("getAnyBelgianMaxValue");
  //   var result = 0;
  //   var bkgSale = globalData.getBookingSegments();
  //   var paxNo = globalData.getBookingSaleData().names.length;
  //
  //   for (var i = 0; i < bkgSale.length; i++) {
  //     if (bkgSale[i].fareType === "BUSINESS PREMIER" || bkgSale[i].fareType === "STANDARD PREMIER") {
  //       // result = result + (paxNo * 8.5);
  //       if ($scope.currency === "GBP"){
  //         result = result + (paxNo * 16);
  //       }
  //       else if ($scope.currency === "EUR"){
  //        result = result + (paxNo * 18.50);
  //       }
  //     } else if (bkgSale[i].fareType === "STANDARD") {
  //       // result = result + (paxNo * 6.0);
  //       if ($scope.currency === "GBP"){
  //         result = result + (paxNo * 10);
  //       }
  //       else if ($scope.currency === "EUR"){
  //        result = result + (paxNo * 11.5);
  //       }
  //     }
  //   }
  //   //  console.log("Result = " + result);
  //   //  console.log("getAmount = " + getAmount());
  //   result = getAmount() - result;
  //
  //   //  console.log("Result = " + result);
  //
  //   return result;
  // }

  function getAnyBelgianMaxValue() {
    //  console.log("getAnyBelgianMaxValue");
    var result = 0;
    var bkgSale = globalData.getBookingSegments();
    let paxNo = 0;
    let adultFare = 0;

    if ($scope.bkgType === 'B') {
      paxNo = globalData.getBookingSaleData().names.filter(name => name.dependant === false).length;
      bkgSale.forEach(segment => {
        adultFare = adultFare + parseFloat(segment.fare) / 100;
      });

      adultFare = adultFare * paxNo;
    }
    else {
      paxNo = globalData.getBookingSaleData().names.length;
    }

    for (var i = 0; i < bkgSale.length; i++) {
      if (bkgSale[i].fareType === 'BUSINESS PREMIER' || bkgSale[i].fareType === 'STANDARD PREMIER') {
        // result = result + (paxNo * 8.5);
        if ($scope.currency === 'GBP'){
          result = result + (paxNo * 16);
        }
        else if ($scope.currency === 'EUR'){
          result = result + (paxNo * 18.50);
        }
      } else if (bkgSale[i].fareType === 'STANDARD') {
        // result = result + (paxNo * 6.0);
        if ($scope.currency === 'GBP'){
          result = result + (paxNo * 10);
        }
        else if ($scope.currency === 'EUR'){
          result = result + (paxNo * 11.5);
        }
      }
    }
    if ($scope.bkgType === 'B') {
      result = adultFare - result;
    }
    else {
      result = getAmount() - result;
    }

    return result;
  }

  function getMinStaffVouchersReqd() {
    var result = 0;
    var pax = globalData.getBookingSaleData().names;
    for (var i = 0; i < pax.length; i++) {
      if (pax[i].dependant === false) {
        result = result + 1;
      }
    }

    return result;
  }

  $scope.incVoucherAmt = function (data) {
    // console.log("PaymentController - incVoucherAmt");
    // console.log("Booking Type = " + $scope.bkgType);
    var vMinValue = 0;
    var vMaxValue = 0;

    var paxNo = globalData.getBookingSaleData().names.length;

    if ($scope.bkgType === 'B') {
      if (IsAnyBelgian() === true) {
        vMaxValue = getAnyBelgianMaxValue();
        // console.log("vMaxValue = " + vMaxValue);
        // //Avail vouchers are less than the Max value then go upto the avail vouchers.
        // if ($scope.availVouchersAmt < vMaxValue) {
        //   vMaxValue = $scope.availVouchersAmt;
        //   console.log("New vMaxValue = " + vMaxValue);
        // }
      } else { // if not ABS then do the usual way.
        // if ($scope.availVouchersAmt <= getAmount()) {
        //   vMaxValue = $scope.availVouchersAmt;
        // }
        // else {
        vMaxValue = getAmount();
        // }
      }
    } else { // if not Eurostar vouchers then no need to check for the ABS
      if ($scope.availVouchersAmt <= getAmount()) {
        vMaxValue = $scope.availVouchersAmt;
      } else {
        vMaxValue = getAmount();
      }
    }

    if (IsPALSBeyond() === true) {
      vMinValue = 0;
    } else if ($scope.bkgType === 'B') {
      vMinValue = getMinStaffVouchersReqd() * $scope.voucherUnitValue;
    } else {
      vMinValue = paxNo * $scope.voucherUnitValue;
    }

    $scope.voucherAmount = parseFloat($scope.voucherAmount);

    if (data === 'p') {
      // console.log("**************************************************");
      // console.log("$scope.voucherAmount = " + $scope.voucherAmount);
      // console.log("$scope.voucherUnitValue = " + $scope.voucherUnitValue);
      // console.log("vMaxValue = " + vMaxValue);
      // console.log("$scope.balancePayment = " + $scope.balancePayment);
      // console.log("**************************************************");

      if (IsPALSBeyond() === true) {
        if ($scope.balancePayment > 0) {
          if ($scope.nonUKEmployee === true) {
            if (($scope.voucherNumber + 1) <= $scope.maxESVouchers) {
              $scope.voucherAmount = $scope.voucherAmount + $scope.voucherUnitValue;
              // console.log("increment" + $scope.voucherAmount);
            }
          } else {
            $scope.voucherAmount = $scope.voucherAmount + $scope.voucherUnitValue;
            // console.log("increment" + $scope.voucherAmount);
          }
        }
      } else if ($scope.bkgType === 'B') {
        if ((($scope.voucherAmount + $scope.voucherUnitValue) <= vMaxValue) && $scope.balancePayment > 0) {
          if ($scope.nonUKEmployee === true) {
            if (($scope.voucherNumber + 1) <= $scope.maxESVouchers) {
              $scope.voucherAmount = $scope.voucherAmount + $scope.voucherUnitValue;
              // console.log("increment" + $scope.voucherAmount);
            }
          } else {
            $scope.voucherAmount = $scope.voucherAmount + $scope.voucherUnitValue;
            // console.log("increment" + $scope.voucherAmount);
          }
        }
      } else {
        if ((($scope.voucherAmount + $scope.voucherUnitValue) <= vMaxValue) && $scope.balancePayment > 0) {
          $scope.voucherAmount = $scope.voucherAmount + $scope.voucherUnitValue;
          // console.log("increment" + $scope.voucherAmount);
        }
      }

    } else if (data === 'm') {
      if (($scope.voucherAmount - $scope.voucherUnitValue) >= vMinValue) {
        $scope.voucherAmount = $scope.voucherAmount - $scope.voucherUnitValue;
        // console.log("decrement" + $scope.voucherAmount);
      }
    }

    $scope.voucherAmount = parseFloat($scope.voucherAmount).toFixed(2);
    // console.log($scope.voucherAmount);
    if ($scope.bkgType === 'B' || IsPALSBeyond()) {
      $scope.voucherNumber = ($scope.voucherAmount / $scope.voucherUnitValue);
      
      // Dirty hack - if balance hasn't changed, then we've reached the max voucher amount so we need to decrement the voucher amount
      if (data === 'p' && IsAnyBelgian() && $scope.balancePayment === getESVBalance()) {
	$scope.voucherAmount -= $scope.voucherUnitValue;
	$scope.voucherNumber = ($scope.voucherAmount / $scope.voucherUnitValue);
      }
    }

    if ($scope.bkgType === 'B') {
      //$scope.voucherNumber = ($scope.voucherAmount/$scope.voucherUnitValue);
      $scope.balancePayment = getESVBalance();
    } else if (IsPALSBeyond() === true) {
      $scope.balancePayment = getPalsBalance();
    } else {
      $scope.balancePayment = getAmount() - $scope.voucherAmount; //getAmountBalance();
      if ($scope.balancePayment < 0) {
        $scope.balancePayment = 0;
      }
    }

    // console.log("Balance Payment = " + $scope.balancePayment);

    if ($scope.balancePayment > 0) {
      $scope.isAmountBalance = true;
    } else {
      $scope.isAmountBalance = false;
    }
    // console.log("$scope.isAmountBalance = " + $scope.isAmountBalance);

  };

  $scope.payBalanceByCard = function () {
    //  console.log("PaymentController - payBalanceByCard");
    $scope.PalsVchrPay = false;
    $scope.vchrRedeemErr = false;
    $scope.vchrRedeemErrMsg = '';
    if ($scope.balancePayment > 0) {
      if ($scope.bkgType === 'B' || (IsPALSBeyond() === true && $scope.voucherNumber > 0)) {
        var vAmt = $scope.voucherAmount;
        var pnrRef = globalData.getElgBkgData().tnr.tnr_ers_pnr;
        var bkgVoucher = {
          'vo_ref': 'ESV',
          'amount': vAmt,
          'currency': $scope.currency,
          'pnr': pnrRef,
          'date': ''
        };
        $scope.vouchers.push(bkgVoucher);
        if ($scope.nonUKEmployee === false) {

          $rootScope.showVoucherMsg = true;
          $rootScope.voucherNumber = $scope.voucherNumber;

          $('#payVchrModal').modal({
            backdrop: 'static'
          });
          //close button of modal initiates the card payment.
        } else {
          $scope.partPay = true;
          init_CardPayment();
        }

      } else {
        calculateVoucher();
        $scope.partPay = true;
        init_CardPayment();
      }

      // openModal();
      // redeemVoucher();
      // closeModal();

      //init_CardPayment(true);
    }
  };
  $scope.tktVchrBkg = function () {
    // Redeem Voucher
    $scope.PalsVchrPay = false;
    $scope.vchrRedeemErr = false;
    $scope.vchrRedeemErrMsg = '';
    //  console.log("PaymentController - tktVchrBkg");
    $scope.isVoucherOnlyBkg = true;
    //calculate from profile vouchers  and redeem response
    if ($scope.bkgType === 'B' || (IsPALSBeyond() === true && $scope.voucherNumber > 0)) {
      if ($scope.nonUKEmployee === false) {

        $rootScope.showVoucherMsg = true;
        $rootScope.voucherNumber = $scope.voucherNumber;

        $('#payVchrModal').modal({
          backdrop: 'static'
        });
      } else {
        openModal();
        ticketBooking();
      }
      // openModal();
      // ticketBooking();
    } else {
      calculateVoucher();
      openModal();
      redeemVoucher();
    }
    //  closeModal();
  };

  $scope.closeVchrMsg = function () {
    if ($scope.balancePayment > 0) {
      $scope.partPay = true;
      init_CardPayment();
    } else {
      openModal();
      ticketBooking();
    }
  };

  function redeemVoucher() {
    if ($scope.vouchers) {
      if ($scope.vouchers.length > 0) {
        $scope.currVoucher = 0;
        $scope.vchrRedeemErr = false;
        $scope.vchrRedeemErrMsg = '';
        sendRedeemVouchers($scope.currVoucher);
      }
    }
  }

  function sendRedeemVouchers(idx) {
    //  console.log("voucher number = " + idx);
    var mres1 = RESOURCES.HOST;
    var nnce = nonceGenerator();
    var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);
    var authData = {};
    authData.uid = userid;
    authData.key = rndString;
    authData.tid = nnce;
    if ($scope.vchrRedeemErr === false) {
      if (idx < $scope.vouchers.length) {
        PaymentService.redeemVoucher($scope.vouchers[idx], authData, mres1)
          .then(function (res) {
            if (res.code) {
              $scope.vchrRedeemErr = true;
              $translate('Text313').then(function (txtMsg) {
                $scope.vchrRedeemErrMsg = txtMsg; //"Voucher Payment Failed - Please make a new booking";
              });
              rollbackVouchers();
              closeModal();
              //  Rollback
            } else {
              $scope.vchrRedeemErr = false;
              $scope.vchrRedeemErrMsg = '';
              $scope.vouchers[idx].note = res.vo_ref + '-' + res.rdm_dcref;
              $scope.vouchers[idx].dcRef = res.rdm_dcref;
              $scope.currVoucher = idx + 1;
              //$scope.vouchers[idx].amount=0;  // dont update the array as it is used by the application in possible rollback;
              updateVoucherBalance(res.vo_ref, res.vo_balance);

              sendRedeemVouchers($scope.currVoucher);
            }
          }, function () {
            $scope.vchrRedeemErr = true;
            $translate('Text313').then(function (txtMsg) {
              $scope.vchrRedeemErrMsg = txtMsg; //"Voucher Payment Failed - Please make a new booking";
            });
            rollbackVouchers();
            closeModal();
            //  Rollback
          });
      } else {
        // if ($scope.balancePayment > 0) {
        //   closeModal();
        //   init_CardPayment(true);
        // }
        // else {
        //   ticketBooking();
        //   // closeModal();
        // }
        ticketBooking();
      }
    }
  }

  function rollbackVouchers() {
    if ($scope.vouchers) {
      if ($scope.vouchers.length > 0) {
        $scope.currVoucher = 0;
        $scope.vchrRedeemErr = false;
        $scope.vchrRedeemErrMsg = '';
        sendRollbackVouchers($scope.currVoucher);
      }
    }
  }

  function sendRollbackVouchers(idx) {
    var mres1 = RESOURCES.HOST;
    var nnce = nonceGenerator();
    var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);
    var authData = {};
    authData.uid = userid;
    authData.key = rndString;
    authData.tid = nnce;
    if ($scope.vchrRedeemErr === false) {
      if (idx < $scope.vouchers.length) {
        if ($scope.vouchers[idx].dcRef) {
          PaymentService.rollbackVoucher($scope.vouchers[idx], authData, mres1)
            .then(function (res) {
              if (res.code) {
                $scope.vchrRedeemErr = true;
                $translate('Text314').then(function (txtMsg) {
                  $scope.vchrRedeemErrMsg = $scope.vouchers[idx].vo_ref + txtMsg;
                });

                //  Rollback
              } else {
                updateVoucherBalance(res.vo_ref, res.vo_balance);
              }
              $scope.currVoucher = idx + 1;
              sendRollbackVouchers($scope.currVoucher);

            }, function () {
              $scope.vchrRedeemErr = true;
              $translate('Text314').then(function (txtMsg) {
                $scope.vchrRedeemErrMsg = $scope.vchrRedeemErrMsg + $scope.vouchers[idx].vo_ref + txtMsg;
              });

              $scope.currVoucher = idx + 1;
              sendRollbackVouchers($scope.currVoucher);
              //  Rollback
            });
        }
      }
    }
  }

  function updateVoucherBalance(vRef, vAmt) {
    var i = 0;
    var total = 0;
    //Update Staff_Voucher_Issued array
    if (($scope.bkgType === 'B') || (IsPALSBeyond() === true)) {
      for (i = 0; i < $rootScope.loggedInUser.ARIA_User.Staff_Voucher_Issued.length; i++) {
        if ($rootScope.loggedInUser.ARIA_User.Staff_Voucher_Issued[i].Voucher_Ref === vRef) {
          $rootScope.loggedInUser.ARIA_User.Staff_Voucher_Issued[i].Voucher_Balance = vAmt;
          break;
        }
      }
      for (i = 0; i < $rootScope.loggedInUser.ARIA_User.Staff_Voucher_Issued.length; i++) {
        total = total + $rootScope.loggedInUser.ARIA_User.Staff_Voucher_Issued[i].Voucher_Balance;
      }
      $rootScope.loggedInUser.ARIA_User.Staff_Voucher_Current_Balance = total;
    } else if ($scope.bkgType === 'M') {
      for (i = 0; i < $rootScope.loggedInUser.ARIA_User.Momentum_Voucher_Issued.length; i++) {
        if ($rootScope.loggedInUser.ARIA_User.Momentum_Voucher_Issued[i].Voucher_Ref === vRef) {
          $rootScope.loggedInUser.ARIA_User.Momentum_Voucher_Issued[i].Voucher_Balance = vAmt;
          break;
        }
      }
      for (i = 0; i < $rootScope.loggedInUser.ARIA_User.Momentum_Voucher_Issued.length; i++) {
        total = total + $rootScope.loggedInUser.ARIA_User.Momentum_Voucher_Issued[i].Voucher_Balance;
      }
      $rootScope.loggedInUser.ARIA_User.Momentum_Voucher_Current_Balance = total;
    } else if ($scope.bkgType === 'I') {
      for (i = 0; i < $rootScope.loggedInUser.ARIA_User.Mitie_Voucher_Issued.length; i++) {
        if ($rootScope.loggedInUser.ARIA_User.Mitie_Voucher_Issued[i].Voucher_Ref === vRef) {
          $rootScope.loggedInUser.ARIA_User.Mitie_Voucher_Issued[i].Voucher_Balance = vAmt;
          break;
        }
      }
      for (i = 0; i < $rootScope.loggedInUser.ARIA_User.Mitie_Voucher_Issued.length; i++) {
        total = total + $rootScope.loggedInUser.ARIA_User.Mitie_Voucher_Issued[i].Voucher_Balance;
      }
      $rootScope.loggedInUser.ARIA_User.Mitie_Voucher_Current_Balance = total;
    }
    //Update Voucher_Issued array
    for (i = 0; i < $rootScope.loggedInUser.ARIA_User.Voucher_Issued.length; i++) {
      if ($rootScope.loggedInUser.ARIA_User.Voucher_Issued[i].Voucher_Ref === vRef) {
        $rootScope.loggedInUser.ARIA_User.Voucher_Issued[i].Voucher_Balance = vAmt;
        break;
      }
    }
    $scope.availVouchersAmt = total;
    /*for (i=0;i<$scope.vouchers.length;i++){
      if ($scope.vouchers[i].vo_ref === vref){
        $scope.vouchers[i].amount = vAmt;
          break;
      }
    }*/
  }

  function setVoucherPayment(vchrOnly) {
    //  console.log("PaymentController - setVoucherPayment");
    var bkgSale = globalData.getBookingSaleData();
    var bkgAmt = 0;
    var voucher = {};

    if (vchrOnly === true) {
      bkgSale.payments = [];
    }

    if ($scope.bkgType === 'B' || (IsPALSBeyond() === true && $scope.voucherNumber > 0)) {
      if (vchrOnly === true) {
        bkgAmt = getActualBookingAmount();
      } else {
        bkgAmt = getActualBookingAmount() - $scope.balancePayment;
      }
      voucher = {
        'type': '12',
        'amount': bkgAmt * 100,
        'currency': $scope.currency,
        'indicator': 'D',
        'note': 'Staff-Voucher Payment'
      };
    } else {
      for (var i = 0; i < $scope.vouchers.length; i++) {
        voucher = {
          'type': '12',
          'amount': $scope.vouchers[i].amount * 100,
          'currency': $scope.vouchers[i].currency,
          'indicator': 'D',
          'note': $scope.vouchers[i].note
        };
      }
    }
    bkgSale.payments.push(voucher);
    // Set the data to the booking sale object.
    globalData.setBookingSaleData(bkgSale);
  }

  function calculateVoucher() {
    var bkgVoucher = {};
    var vAmt = $scope.voucherAmount;
    var pnrRef = globalData.getElgBkgData().tnr.tnr_ers_pnr;
    var dateRef = getDateRef();
    var vData = [];
    if (($scope.bkgType === 'B') || (IsPALSBeyond() === true)) {
      vData = $rootScope.loggedInUser.ARIA_User.Staff_Voucher_Issued;
    } else if ($scope.bkgType === 'M') {
      vData = $rootScope.loggedInUser.ARIA_User.Momentum_Voucher_Issued;
    } else if ($scope.bkgType === 'I') {
      vData = $rootScope.loggedInUser.ARIA_User.Mitie_Voucher_Issued;
    }

    $scope.vouchers = [];
    for (var i = 0; i < vData.length; i++) {
      if (vAmt > 0 && vData[i].Voucher_Balance > 0) {
        if (vAmt <= vData[i].Voucher_Balance) {
          bkgVoucher = {
            'vo_ref': vData[i].Voucher_Ref,
            'amount': vAmt,
            'currency': $scope.currency,
            'pnr': pnrRef,
            'date': dateRef
          };
          vAmt = 0;
          $scope.vouchers.push(bkgVoucher);
          break;
        } else if (vAmt > vData[i].Voucher_Balance) {
          bkgVoucher = {
            'vo_ref': vData[i].Voucher_Ref,
            'amount': vData[i].Voucher_Balance,
            'currency': $scope.currency,
            'pnr': pnrRef,
            'date': dateRef
          };
          vAmt = Number((vAmt - vData[i].Voucher_Balance).toFixed(2));
          $scope.vouchers.push(bkgVoucher);
        }
      }
    }
    // console.log("PaymentController - calculateVoucher");
    // console.log($scope.vouchers);
  }

  function getDateRef() {
    var today = new Date();
    var dateRef = today.getFullYear().toString();

    if (today.getMonth() + 1 > 9) {
      dateRef = dateRef + (today.getMonth() + 1).toString();
    } else {
      dateRef = dateRef + '0' + (today.getMonth() + 1).toString();
    }
    if (today.getDate() > 9) {
      dateRef = dateRef + today.getDate().toString();
    } else {
      dateRef = dateRef + '0' + today.getDate().toString();
    }
    if (today.getHours() > 9) {
      dateRef = dateRef + today.getHours().toString();
    } else {
      dateRef = dateRef + '0' + today.getHours().toString();
    }
    if (today.getMinutes() > 9) {
      dateRef = dateRef + today.getMinutes().toString();
    } else {
      dateRef = dateRef + '0' + today.getMinutes().toString();
    }
    if (today.getSeconds()) {
      dateRef = dateRef + today.getSeconds().toString();
    } else {
      dateRef = dateRef + '0' + today.getSeconds().toString();
    }
    if (today.getMilliseconds() >= 0 || today.getMilliseconds() <= 9) {
      dateRef = dateRef + '00' + today.getMilliseconds();
    } else if (today.getMilliseconds() >= 10 || today.getMilliseconds() <= 99) {
      dateRef = dateRef + '0' + today.getMilliseconds();
    } else {
      dateRef = dateRef + today.getMilliseconds();
    }
    // console.log("PaymentController - getDateRef");
    // console.log(dateRef)
    return dateRef;
  }
  $scope.checkAlphaNumeric = function (event) {
    var x = event.which || event.keyCode;

    //alert("which = " + event.which + "- keyCode = " + event.keyCode + " -  charCode = " + event.charCode);

    //block all keypress except tab.
    if ((x > 47 && x < 58) || (x > 64 && x < 91) || (x > 96 && x < 123) || (x == 32) || (x == 8)) {
      //alert(x);
    } else {
      event.preventDefault();
    }
  };
  $scope.filterNumValue = function ($event) {
    if (isNaN(String.fromCharCode($event.keyCode))) {
      $event.preventDefault();
    }
  };
  $scope.filterAmount = function ($event) {
    $event.preventDefault();
  };

  function isUSCA() {
    const country = $scope.countries.find(country => {
      if (country.name === $scope.billingAddress.country) {
        return country;
      }
    });

    return ['US', 'CA'].includes(country?.code);
  }

  $scope.isUSCA = () => isUSCA();

  $scope.updateBillingAddress = function () {
    if (($scope.billingAddress.line_1 == undefined) || ($scope.billingAddress.line_2 == undefined) || ($scope.billingAddress.country == undefined) || ($scope.phone == undefined) || ($scope.billingAddress.line_1 == '') || ($scope.billingAddress.line_2 == '') || (isUSCA() && ($scope.billingAddress.line_3 == '' || $scope.billingAddress.line_3 == undefined)) || ($scope.billingAddress.country == '') || ($scope.phone == '')) {
      $scope.showEdit = true;
      $scope.contactInfoError = true;
    } else {
      $scope.showEdit = false;
      $scope.contactInfoError = false;
      init_CardPayment();
    }
  };

  function dc_setup(mRef, amtPay, payCur) {
    let bkgData = globalData.getBookingSaleData();
    let lang = $translate.use();
    let pageSetId;

    if (lang === 'fr') {
      pageSetId = RESOURCES.PAGE_SET_ID_FR;
    }
    else if (lang === 'be') {
      pageSetId = RESOURCES.PAGE_SET_ID_NL;
    }
    else {
      pageSetId = RESOURCES.PAGE_SET_ID;
    }

    const country = $scope.countries.find(country => {
      if (country.name === $scope.billingAddress.country){
        return country;
      }
    });

    let mres1 = RESOURCES.HOST;
    const dcRetSuccessUrl = `${RESOURCES.DC_RETURN_HOST}/success.html`;
    let msg = {
      'PaymentSetupServiceIn': {
        'id': bkgData.id,
        'locator': $scope.pnr,
        'customer': {
          'firstName': bkgData.contact_details.firstname,
          'lastName': bkgData.contact_details.surname,
          'email': bkgData.contact_details.email[0],
          'phoneNumber': $scope.phone ,
          'address': {
            'street': $scope.billingAddress.line_1,
            'city': $scope.billingAddress.line_3 ? $scope.billingAddress.line_3 : $scope.billingAddress.line_2 ,
            'postalCode': $scope.billingAddress.postcode,
            'countryCode': country.code
          }
        },
        'merchantReference': mRef,
        'currency': payCur,
        'value': amtPay*100,
        'pageSetId': pageSetId,
        'paymentLocation': 'HQ',
        'returnUrl': dcRetSuccessUrl
      }
    };

    PaymentService.paymentSetup(msg, mres1)
      .then(function (res) {
        var payment = {};
        payment.merchantReference = mRef;
        payment.currency = payCur;
        payment.amount = amtPay;

        if (res != null) {
          globalData.setPayment(payment);
          closeModal();
        } else {
          if (res.PaymentSetupServiceOut) {
            if (res.PaymentSetupServiceOut.url) {
              globalData.setPayment(payment);
              $scope.url = $sce.trustAsResourceUrl(res.PaymentSetupServiceOut.url);

              closeModal();
            } else {
              closeModal();
              $scope.payErr = true;
              $translate('Text255').then(function (txtMsg) {
                $scope.payErrMsg = '1- ' + txtMsg;
              });

              payment = {};
              globalData.setPayment(payment); // clear payment object
              if ($scope.bkgType === 'B' || (IsPALSBeyond() === true && $scope.voucherNumber > 0)) {
                $scope.partPay = true;
                init_CardPayment();
              } else if ($scope.vouchers) {
                if ($scope.vouchers.length > 0) {
                  $scope.partPay = true;
                  init_CardPayment();
                }
              } else {
                $location.path('/payment'); //Goto Payment page to pay by card.
              }
            }
          } else {
            $scope.payErr = true;
            $translate('Text255').then(function (txtMsg) {
              $scope.payErrMsg = '1- ' + txtMsg;
            });
            //$scope.payErrMsg = "1- Unable to connect to the payment gateway";
            payment = {};
            globalData.setPayment(payment); // clear payment object
            if ($scope.vouchers) {
              if ($scope.vouchers.length > 0) {
                $scope.partPay = true;
                init_CardPayment();
              }
            } else {
              $location.path('/payment'); //Goto Payment page to pay by card.
            }
          }
        }
      }, function () {
        $scope.payErr = true;
        $translate('Text255').then(function (txtMsg) {
          $scope.payErrMsg = '1- ' + txtMsg;
        });
        globalData.setPayment({}); // clear payment object
        if ($scope.vouchers) {
          if ($scope.vouchers.length > 0) {
            $scope.partPay = true;
            init_CardPayment();
          }
        } else {
          $location.path('/payment'); //Goto Payment page to pay by card.
        }
      });
  }

  $scope.initiatePayment = (payload, shouldFinalisePayment = false) => {
    var payment = globalData.getPayment();
    payment.pspData = payload;
    payment.shouldFinalisePayment = shouldFinalisePayment;

    globalData.setPayment(payment);

    openModal();

    if ($scope.vouchers) {
      if ($scope.vouchers.length > 0) {
        if ($scope.bkgType === 'B' || (IsPALSBeyond() === true && $scope.voucherNumber > 0)) {
          ticketBooking();
        } else {
          redeemVoucher();
        }

      } else {
        ticketBooking();
      }
    } else {
      ticketBooking();
    }
  };

  $scope.openModal = () => {
    document.getElementById('modal').style.display = 'block';
    document.getElementById('fade').style.display = 'block';
  };

  $scope.closeModal = () => {
    document.getElementById('modal').style.display = 'none';
    document.getElementById('fade').style.display = 'none';
  };

  function payAndConfirm(dc_reference){
    var payment = globalData.getPayment();
    payment.datacash_reference = dc_reference;
    globalData.setPayment(payment);
    openModal();
    if ($scope.vouchers) {
      if ($scope.vouchers.length > 0) {
        if ($scope.bkgType === 'B' || (IsPALSBeyond() === true && $scope.voucherNumber > 0)) {
          ticketBooking();
        } else {
          redeemVoucher();
        }

      } else {
        ticketBooking();
      }
    } else {
      ticketBooking();
    }
  }

  $scope.eTapTicket = function (data) {
    $scope.etap_Ticket_type = data;
    SendEtap();
  };

  function ticketBooking() {
    if ($scope.isVoucherOnlyBkg === true) {
      setVoucherPayment(true);
    } else {
      setPaymentData();
    }
    $translate('Text260').then(function (txtMsg) {
      $scope.paymentMsg = '3- ' + txtMsg;
    });
    //$scope.paymentMsg = "3. Ticketing the booking.  Please wait...";
    $scope.showBilingAddress = false;
    var bkgTkt = getTicketingData();
    //openModal();
    var mres1 = RESOURCES.HOST;
    bookingService.ticketJourney(bkgTkt, mres1)
      .then(function (res) {
        if (res.wsi_error) {
          $translate('Text258').then(function (txtMsg) {
            $scope.tktError = '3- ' + txtMsg;
          });
          if ($scope.vouchers.length > 0) {
            rollbackVouchers();
            $scope.showESV = false;
          }
          $scope.tktSuccess = false;
          $scope.tktSuccessMsg = '';
          closeModal();
          $scope.isVoucherOnlyBkg = false;

          if (globalData.dfdPromiseInitiatePayment) {
            globalData.dfdPromiseInitiatePayment.reject(res.wsi_error);
          }
        } else {
          const pspData = res.TicketBookingServiceOut.pspData;

          if (globalData.dfdPromiseInitiatePayment) {
            globalData.dfdPromiseInitiatePayment.resolve(pspData);
          }

          if(pspData?.data?.action) { // action for 3DS - give back control to the CheckoutComponent
            return;
          }

          $scope.tktError = '';
          $scope.tktSuccess = true;

          $translate('Text261').then(function (txtMsg) {
            $scope.tktSuccessMsg = '3- ' + txtMsg + res.TicketBookingServiceOut.tnr.tnr_ers_pnr;
          });
          //$scope.tktSuccessMsg = 'Ticketing Successful for the booking reference ' + res.TicketBookingServiceOut.tnr.tnr_ers_pnr;
          closeModal();
          openModal();
          generateCase(res);
          closeModal();
          globalData.setbookingType(''); // Set booking type to ''.
          res.TicketBookingServiceOut = s3_ProcessPaxInfo(res.TicketBookingServiceOut);
          globalData.setRefCode(res.TicketBookingServiceOut.tnr.tnr_ers_pnr);
          globalData.setElgBkgData(res.TicketBookingServiceOut);
          // $location.path('/retrieve');
          var payment = {};
          globalData.setPayment(payment); // clear payment object
          $scope.isVoucherOnlyBkg = false;

          // checking for booking types:
          // (M)omentum, m(I)tie, (F)ip, (P)als, (B)lue voucher, (T)fl and MICE(Y)
          if (($scope.bkgType === 'Y') || ($scope.bkgType === 'M') || ($scope.bkgType === 'I') || ($scope.bkgType === 'F') || ($scope.bkgType === 'P' && HaveDirectServices() === true)) {
            /*
            $scope.showEtapTicketing = true;
            $scope.pdfFlag = true;
              for (var i=0;i<$scope.paxNames.length;i++) {
                if (($scope.paxNames[i].etap_email === "") && ($scope.paxNames[i].etap_phone === "")){
                    $scope.pdfFlag = false;
                      break;
                }
              }
            $scope.passbookFlag = true;
              for (var j=0;j<$scope.paxNames.length;j++) {
                if (($scope.paxNames[j].etap_email === "")){
                    $scope.passbookFlag = false;
                      break;
                }
              }*/
            SendEtap();
          } else if ($scope.bkgType === 'P') {
            SendEtap();
          } else if ($scope.bkgType === 'B') {
            SendEtap();
          } else if ($scope.bkgType === 'T') {
            SendEtap();
          } else {
            $location.path('/retrieve');
          }
        }
      }, function (err) {
        $translate('Text258').then(function (txtMsg) {
          $scope.tktError = '3- ' + err;
        });
        if ($scope.vouchers.length > 0) {
          rollbackVouchers();
          $scope.showESV = false;
        }
        $scope.tktSuccess = false;
        $scope.tktSuccessMsg = '';
        $scope.isVoucherOnlyBkg = false;
        closeModal();


        if (globalData.dfdPromiseInitiatePayment) {
          globalData.dfdPromiseInitiatePayment.reject(err);
        }
        // alert(err);
      }); //End web service
  }

  function HaveDirectServices() {
    var result = true;
    var bkgSegs = globalData.getBookingSegments();
    //  console.log("HaveDirectServices()");
    //  console.log(bkgSegs);
    for (var i = 0; i < bkgSegs.length; i++) {
      if (bkgSegs[i].carrier != 'ES') {
        result = false;
        break;
      }
    }
    return result;
  }

  function setPaymentData() {
    var bkgSale = globalData.getBookingSaleData();
    bkgSale.payments = [];
    var dcPay = globalData.getPayment();
    var bkgCurrency = globalData.getCurrency();
    // var pName = dcPay.query_resp.Response.HpsTxn.DynamicCapture.capf1.split("+");
    //add Payment data
    var amount = 0;
    var country = $scope.countries.find(country => {
      if (country.name === $scope.billingAddress.country){
        return country;
      }
    });
    // Voucher payment in voucher + card payment scenario.
    if ($scope.vouchers) {
      if ($scope.vouchers.length > 0) {
        amount = $scope.balancePayment * 100;
      } else {
        amount = getAmount() * 100;
      }
    } else {
      amount = getAmount() * 100;
    }
    var bkg_payment = {
      'type': '4',
      'amount': amount,
      'currency': bkgCurrency,
      'indicator': 'D',
      'note': dcPay.datacash_reference,      //dcPay.auth_resp.Response.CardTxn.token,
      'cc_detail': {
        'token': '', //dcPay.auth_resp.Response.CardTxn.token,
        'expiry': '', //dcPay.query_resp.Response.HpsTxn.expirydate,
        'input_method': 'K',
        'scheme': '', //dcPay.query_resp.Response.HpsTxn.card_scheme,
        'payment_ref': dcPay.datacash_reference, // dcPay.auth_resp.Response.datacash_reference,
        'auth_code': '', // dcPay.auth_resp.Response.CardTxn.authcode,
        'elgar_ref': getMerchantReference(), // dcPay.auth_resp.Response.merchantreference,
        'firstname': bkgSale.names[0].firstname,
        'surname': bkgSale.names[0].surname,
        'payment_address': {
          'line_1': $scope.billingAddress.line_1,
          'line_2': ($scope.billingAddress.line_2 ? $scope.billingAddress.line_2 : '')  + ' ' + ($scope.billingAddress.line_3 ? $scope.billingAddress.line_3 : ''),
          'stateOrProvince': ($scope.billingAddress.line_3 ? $scope.billingAddress.line_3 : ''),
          'country': $scope.billingAddress.country,
          'postcode': $scope.billingAddress.postcode,
          's3_city' : $scope.billingAddress.line_3 ? $scope.billingAddress.line_3 : $scope.billingAddress.line_2 ,
          's3_country_code' : country.code,
          's3_phoneCountryCode' : country.dial.substr(1),
          's3_phoneNumber': $scope.phone
        }
      }
    };

    bkg_payment.pspData = dcPay.pspData;
    bkg_payment.shouldFinalisePayment = dcPay.shouldFinalisePayment;

    bkgSale.payments.push(bkg_payment);
    // Set the data to the booking sale object.
    if ($scope.vouchers) {
      if ($scope.vouchers.length > 0) {
        // Voucher payment in voucher + card payment scenario.
        setVoucherPayment(false);
      }
    }
    globalData.setBookingSaleData(bkgSale);
  }

  function getTicketingData() {
    let bkgSale = globalData.getBookingSaleData();
    let bkgData = globalData.getElgBkgData();
    let tktRequest = {};
    let passengers = [];
    let segments = [];
    let payments_card = bkgSale.payments.filter(payment => payment.type === '4');
    let payments_voucher = bkgSale.payments.filter(payment => payment.type === '12');

    let nnce = nonceGenerator();
    let userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    let rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

    bkgSale.names.forEach(name => {
      passengers.push({'first_name': name.firstname, 'last_name': name.surname, 'type': name.paxtype});
    });

    bkgSale.segments.forEach(segment => {
      let month = (segment.dep_date_full.getMonth()+1) <10 ? '0' + (segment.dep_date_full.getMonth()+1).toString(): (segment.dep_date_full.getMonth()+1).toString();
      let day = (segment.dep_date_full.getDate() < 10) ? '0' +(segment.dep_date_full.getDate()) : segment.dep_date_full.getDate();

      let departDate = `${segment.dep_date_full.getFullYear()}-${month}-${day} ${segment.dep_time.substring(0,2)}:${segment.dep_time.substring(2,4)}:00`;

      segments.push({ 'train_number': segment.train_number.toString(), 'carrier': segment.carrier,
        'depart_station': segment.origin, 'arrival_station': segment.destination,
        'depart_datetime': departDate});
    });

    tktRequest.Aria_User_Name = userid;
    tktRequest.Aria_Passcode = rndString;
    tktRequest.Client_Tranx_Id = nnce;

    tktRequest.id = bkgSale.id;
    tktRequest.locator = bkgData.tnr.tnr_ers_pnr;
    tktRequest.payments = bkgSale.payments;
    if (payments_card && payments_card.length > 0){
      tktRequest.payments_card = payments_card;
    }

    // get no of dependants
    let dependants_no = bkgSale.names.filter(name => name.dependant).length;
    let vouchers_no = $scope.voucherNumber;

    payments_voucher.forEach(p => {
      p.dependants_no = dependants_no;
      p.vouchers_no = vouchers_no;
    });
    
    if (payments_voucher && payments_voucher.length > 0){
      tktRequest.payments_voucher =  payments_voucher;
    }
    
    tktRequest.currency = $scope.currency;
    tktRequest.s3_passengers = passengers;
    tktRequest.s3_segments = segments;
    tktRequest.s3_paymentLocation = 'HQ';

    return tktRequest;
  }

  function generateCase(bookingData) {
    var caseData = {};
    var enqData = globalData.getBookingEnquiryData();

    var nnce = nonceGenerator();
    var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

    caseData.Aria_User_Name = userid;
    caseData.Aria_Passcode = rndString;
    caseData.Client_Tranx_Id = nnce;

    caseData.booking_type = globalData.getbookingType();
    caseData.booking_reference = bookingData.TicketBookingServiceOut.tnr.tnr_ers_pnr;
    caseData.es_vo_used = $scope.voucherNumber;

    caseData.lead_passenger_title = '';
    caseData.lead_passenger_first_name = bookingData.TicketBookingServiceOut.tnr.tnr_names[0].name_initials;
    caseData.lead_passenger_last_name = bookingData.TicketBookingServiceOut.tnr.tnr_names[0].name_surname;
    caseData.number_of_passengers = enqData.paxNum;

    var oDate = new Date(enqData.dDateFull);
    caseData.outbound_dep_date_time = oDate.getDate() + '-' + (oDate.getMonth() + 1) + '-' + oDate.getFullYear();
    caseData.outbound_origin_station_code = enqData.originNumeric;
    caseData.outbound_origin_station_name = enqData.originFullName;
    caseData.outbound_destination_station_code = enqData.destinationNumeric;
    caseData.outbound_destination_station_name = enqData.destinationFullName;
    if (enqData.fareType === 'STANDARD') {
      caseData.outbound_cos_id = 'B';
    } else if (enqData.fareType === 'STANDARD PREMIER') {
      caseData.outbound_cos_id = 'H';
    } else if (enqData.fareType === 'BUSINESS PREMIER') {
      caseData.outbound_cos_id = 'A';
    }
    if (enqData.isReturnBooking) {
      var oRDate = new Date(enqData.returndDateFull);
      caseData.inbound_dep_date_time = oRDate.getDate() + '-' + (oRDate.getMonth() + 1) + '-' + oRDate.getFullYear();
      caseData.inbound_origin_station_code = enqData.returnOriginNumeric;
      caseData.inbound_origin_station_name = enqData.returnOriginFullName;
      caseData.inbound_destination_station_code = enqData.returnDestinationNumeric;
      caseData.inbound_destination_station_name = enqData.returnDestinationFullName;
      if (enqData.returnFareType === 'STANDARD') {
        caseData.inbound_cos_id = 'B';
      } else if (enqData.returnFareType === 'STANDARD PREMIER') {
        caseData.inbound_cos_id = 'H';
      } else if (enqData.returnFareType === 'BUSINESS PREMIER') {
        caseData.inbound_cos_id = 'A';
      }
    }
    if ($scope.vouchers) {
      for (var i = 0; i < $scope.vouchers.length; i++) {
        if ($scope.vouchers[i].vo_ref != 'ESV') {
          caseData['vo_ref_' + (i + 1)] = $scope.vouchers[i].vo_ref;
          caseData['vo_ref_' + (i + 1) + '_amount'] = parseFloat($scope.vouchers[i].amount).toFixed(2);
        }
      }
    }
    var mres1 = RESOURCES.HOST;
    bookingService.createCase(caseData, mres1)
      .then(function (res) {
        if (res.Booking_Case_Response.status == 'FAILURE') {
          $scope.bookingError = res.Booking_Case_Response.status;
        } else if (res.Booking_Case_Response.status == 'SUCCESS') {
          updateUserBkgCount(res);
        }
      }, function (err) {
        //error
        $scope.bookingError = err;
      });
  }

  function updateUserBkgCount(data) {
    switch (data.Booking_Case_Response.booking_type) {
    case 'P':
      $rootScope.loggedInUser.ARIA_User.PALS_Booking_Used = data.Booking_Case_Response.used_points;
      $rootScope.loggedInUser.ARIA_User.PALS_Remaining = data.Booking_Case_Response.balance_points;
      if (IsPALSBeyond() === true && $scope.voucherNumber > 0) {
        if ($scope.nonUKEmployee === true) {
          $rootScope.loggedInUser.ARIA_User.ESV_remaining = ($rootScope.loggedInUser.ARIA_User.ESV_remaining - $scope.voucherNumber);
        }
      }
      break;
    case 'B':
      //$rootScope.loggedInUser.ARIA_User.Blue_Voucher_Used = data.Booking_Case_Response.used_points;
      //$rootScope.loggedInUser.ARIA_User.Blue_Voucher_Current_Balance = data.Booking_Case_Response.balance_points;
      if ($scope.nonUKEmployee === true) {
        $rootScope.loggedInUser.ARIA_User.ESV_remaining = ($rootScope.loggedInUser.ARIA_User.ESV_remaining - $scope.voucherNumber);
        // console.log("$rootScope.loggedInUser.ARIA_User.ESV_remaining = " + $rootScope.loggedInUser.ARIA_User.ESV_remaining);
      }
      break;
    case 'G':
      //$rootScope.loggedInUser.ARIA_User.Blue_Voucher_Used.Green_Voucher_Used = data.Booking_Case_Response.green_voucher_used_date;
      break;
    }
  }

  function getMerchantReference() {
    var bkgData = globalData.getElgBkgData();
    var today = new Date();
    var result;

    if (bkgData.tnr.tnr_ers_pnr) {
      result = bkgData.tnr.tnr_ers_pnr + '_' + today.getFullYear() + (today.getMonth() + 1) + today.getDate() + today.getHours() + today.getMinutes() + today.getSeconds();
      return result;
    }
  }

  /*
   function getAmount(){
    var result = 0;
    if ($scope.bkgType === 'B'){
      result = getAmount_Staff();
    }
    else if (IsPALSBeyond() === true) {
      result = getAmount_PalsBeyond();
    }
    else {
      result = getAmount_Others();
    }

    return result;
  }

  */

  /*function getAmount_Others() {
    // returns tickt amount.
    var oSegments = globalData.getBookingSegments();
    var bkgSale = globalData.getBookingSaleData();

    var result = 0;
    var paxnum = bkgSale.names.length;
    for (var iCount=0 ; iCount<oSegments.length; iCount++) {
       result += (parseFloat(oSegments[iCount].fare)/100);
    }
    result = (result * paxnum);
    return result;
  }
  */

  //function returns the full booking amount.
  function getActualBookingAmount() {
    // returns tickt amount.
    var oSegments = globalData.getBookingSegments();
    var bkgSale = globalData.getBookingSaleData();

    var result = 0;
    var paxnum = bkgSale.names.length;
    for (var iCount = 0; iCount < oSegments.length; iCount++) {
      result += (parseFloat(oSegments[iCount].fare) / 100);
    }
    result = (result * paxnum);
    return result;
  }

  // function getESVBalance() {
  //   var result = 0;
  //
  //   var i = 0;
  //   var j = 0;
  //
  //   var vFirstAdult = 0;
  //   var oSegments = globalData.getBookingSegments();
  //   var bkgSale = globalData.getBookingSaleData();
  //
  //   // vouchers required per pax per seg. even for dependants
  //   // vouchers allocated to first adult for a segment will be allocated to dependants.
  //
  //   // console.log("getESVBalance : ");
  //   var staffVouchers = [];
  //   var voucher = {
  //     paxID: "",
  //     vo_num: 0,
  //     vo_req: 0,
  //     tot: 0,
  //     dependant: false
  //   };
  //   //initialise vouchers
  //   for (i = 0; i < oSegments.length; i++) {
  //     var vReq = (parseFloat(oSegments[i].fare) / 100) / ($scope.voucherUnitValue);
  //     for (j = 0; j < bkgSale.names.length; j++) {
  //       voucher = {
  //         segId: (i + 1),
  //         paxID: (j + 1),
  //         vo_num: 0,
  //         vo_req: vReq,
  //         unpaid: vReq,
  //         dependant: bkgSale.names[j].dependant
  //       };
  //       staffVouchers.push(voucher);
  //     }
  //   }
  //
  //   var vNum = $scope.voucherNumber;
  //
  //   //New code for voucher allocation
  //   // Step 1: Allocate min vouchers for all pax except dependents
  //   // while (vNum > 0) {
  //   for (i = 0; i < staffVouchers.length; i++) {
  //     if (vNum > 0) {
  //       if (staffVouchers[i].dependant === false && staffVouchers[i].segId === 1) {
  //         staffVouchers[i].vo_num = staffVouchers[i].vo_num + 1;
  //         vNum = vNum - 1;
  //       }
  //     } else {
  //       break;
  //     }
  //   }
  //   // }
  //   // Step 2: Allocate vouchers to first pax
  //   if (vNum > 0) {
  //     for (i = 0; i < staffVouchers.length; i++) {
  //       if (staffVouchers[i].dependant === false && staffVouchers[i].paxID === 1) {
  //         while ((vNum > 0) && (staffVouchers[i].vo_num < staffVouchers[i].vo_req)) {
  //           staffVouchers[i].vo_num = staffVouchers[i].vo_num + 1;
  //           vNum = vNum - 1;
  //         }
  //       }
  //     }
  //   }
  //   //Step 3 allocate to rest of the passengers
  //   if (vNum > 0) {
  //     for (i = 0; i < staffVouchers.length; i++) {
  //       if (staffVouchers[i].dependant === false && staffVouchers[i].paxID != 1) {
  //         while ((vNum > 0) && (staffVouchers[i].vo_num < staffVouchers[i].vo_req)) {
  //           staffVouchers[i].vo_num = staffVouchers[i].vo_num + 1;
  //           vNum = vNum - 1;
  //         }
  //       }
  //     }
  //   }
  //   //New code for voucher allocation
  //   //get voucher value of the first adult per seg;
  //
  //   for (i = 0; i < oSegments.length; i++) {
  //     vFirstAdult = 0;
  //     //get voucher allocation of first adult;
  //     for (j = 0; j < staffVouchers.length; j++) {
  //       if ((i + 1) === staffVouchers[j].segId && staffVouchers[j].dependant === false) {
  //         vFirstAdult = staffVouchers[j].vo_num;
  //         break;
  //       }
  //     }
  //     //set voucher allocation of first dependants;
  //     for (j = 0; j < staffVouchers.length; j++) {
  //       if ((i + 1) === staffVouchers[j].segId && staffVouchers[j].dependant === true) {
  //         staffVouchers[j].vo_num = vFirstAdult;
  //       }
  //     }
  //   }
  //   //Calculate the unpaid
  //   var unpaid = 0;
  //   for (i = 0; i < staffVouchers.length; i++) {
  //     unpaid += (staffVouchers[i].vo_req - staffVouchers[i].vo_num);
  //   }
  //
  //   result = unpaid * $scope.voucherUnitValue;
  //   return result;
  // }

  function getESVBalance() {
    var result = 0;
    var i = 0;
    var j = 0;
    var vFirstAdult = 0;
    var oSegments = globalData.getBookingSegments();
    var bkgSale = globalData.getBookingSaleData();

    // vouchers required per pax per seg. even for dependants
    // vouchers allocated to first adult for a segment will be allocated to dependants.
    // console.log("getESVBalance : ");
    var staffVouchers = [];
    var voucher = {
      paxID: '',
      vo_num: 0,
      vo_req: 0,
      tot: 0,
      dependant: false
    };
    //initialise vouchers
    for (i = 0; i < oSegments.length; i++) {
      var vReq = (parseFloat(oSegments[i].fare) / 100) / ($scope.voucherUnitValue);
      for (j = 0; j < bkgSale.names.length; j++) {
        voucher = {
          segId: (i + 1),
          paxID: (j + 1),
          vo_num: 0,
          vo_req: vReq,
          unpaid: vReq,
          dependant: bkgSale.names[j].dependant
        };
        staffVouchers.push(voucher);
      }
    }
    var vNum = $scope.voucherNumber;
    //New code for voucher allocation
    // Step 1: Allocate min vouchers for all pax except dependents
    for (i = 0; i < staffVouchers.length; i++) {
      if (vNum > 0) {
        if (staffVouchers[i].dependant === false && staffVouchers[i].segId === 1) {
          staffVouchers[i].vo_num = staffVouchers[i].vo_num + 1;
          vNum = vNum - 1;
        }
      } else {
        break;
      }
    }
    // Step 2: Allocate vouchers to first pax
    if (vNum > 0) {
      for (i = 0; i < staffVouchers.length; i++) {
        if (staffVouchers[i].dependant === false && staffVouchers[i].paxID === 1) {
          while ((vNum > 0) && ((staffVouchers[i].vo_num < staffVouchers[i].vo_req) && (staffVouchers[i].vo_req - staffVouchers[i].vo_num) >= 1)) {
            staffVouchers[i].vo_num = staffVouchers[i].vo_num + 1;
            vNum = vNum - 1;
          }
        }
      }
    }
    //Step 3 allocate to rest of the passengers
    if (vNum > 0) {
      for (i = 0; i < staffVouchers.length; i++) {
        if (staffVouchers[i].dependant === false && staffVouchers[i].paxID != 1) {
          while ((vNum > 0) && ((staffVouchers[i].vo_num < staffVouchers[i].vo_req) && (staffVouchers[i].vo_req - staffVouchers[i].vo_num) >= 1)) {
            staffVouchers[i].vo_num = staffVouchers[i].vo_num + 1;
            vNum = vNum - 1;
          }
        }
      }
    }
    //New code for voucher allocation
    //get voucher value of the first adult per seg;
    for (i = 0; i < oSegments.length; i++) {
      vFirstAdult = 0;
      //get voucher allocation of first adult;
      for (j = 0; j < staffVouchers.length; j++) {
        if ((i + 1) === staffVouchers[j].segId && staffVouchers[j].dependant === false) {
          vFirstAdult = staffVouchers[j].vo_num;
          break;
        }
      }
      //set voucher allocation of first dependants;
      for (j = 0; j < staffVouchers.length; j++) {
        if ((i + 1) === staffVouchers[j].segId && staffVouchers[j].dependant === true) {
          staffVouchers[j].vo_num = vFirstAdult;
        }
      }
    }
    //Calculate the unpaid
    var unpaid = 0;
    for (i = 0; i < staffVouchers.length; i++) {
      unpaid += (staffVouchers[i].vo_req - staffVouchers[i].vo_num);
    }
    result = unpaid * $scope.voucherUnitValue;
    return result;
  }

  function getPalsBalance() {
    //PALS beyond journey
    var result = 0;

    var i = 0;
    var j = 0;

    var vFirstAdult = 0;
    var vFirstAdultID = 0;

    var oSegments = globalData.getBookingSegments();
    var bkgSale = globalData.getBookingSaleData();
    // console.log(oSegments);
    // vouchers required per pax per seg. even for dependants
    // vouchers allocated to first adult for a segment will be allocated to dependants.

    // console.log("getPalsBalance : ");
    var staffVouchers = [];
    var voucher = {
      paxID: '',
      vo_amt: 0,
      vo_amt_req: 0,
      unpaid: 0,
      dependant: false
    };
    //initialise vouchers
    for (i = 0; i < oSegments.length; i++) {
      if (oSegments[i].fare > 0) {
        var vReq = (parseFloat(oSegments[i].fare) / 100);

        for (j = 0; j < bkgSale.names.length; j++) {
          voucher = {
            segId: (i + 1),
            paxID: (j + 1),
            vo_amt: 0,
            vo_amt_req: vReq,
            unpaid: vReq,
            dependant: bkgSale.names[j].dependant
          };
          staffVouchers.push(voucher);
        }
      }
    }

    //get Id for the first adult passenger
    for (i = 0; i < staffVouchers.length; i++) {
      if (staffVouchers[i].dependant === false) {
        vFirstAdultID = staffVouchers[i].paxID;
        break;
      }
    }

    // console.log("staffVouchers");
    // console.log(staffVouchers);
    var vAmt = $scope.voucherNumber * $scope.voucherUnitValue;
    // console.log("voucher number = " + vAmt);
    // console.log("vFirstAdultID = " + vFirstAdultID);
    //allocate vouchers from $scope.voucherNumber to first adult passenger
    // console.log("####################################");
    for (i = 0; i < staffVouchers.length; i++) {
      // console.log("i = " + i);
      // console.log("staffVouchers[i].paxId = " + staffVouchers[i].paxID);
      // console.log("vFirstAdultID = " + vFirstAdultID);

      if (staffVouchers[i].paxID === vFirstAdultID) {
        // console.log("vAmt = " + vAmt);
        if (vAmt > 0) {
          if (staffVouchers[i].vo_amt_req >= vAmt) {
            staffVouchers[i].vo_amt = vAmt;
            vAmt = 0;
          } else if (staffVouchers[i].vo_amt_req < vAmt) {
            staffVouchers[i].vo_amt = staffVouchers[i].vo_amt_req;
            vAmt = vAmt - staffVouchers[i].vo_amt_req;
          }
        }
      }
    }

    // allocate the rest to other non dependant employees
    if (vAmt > 0) {
      for (i = 0; i < staffVouchers.length; i++) {
        if (vAmt > 0) {
          if (staffVouchers[i].paxID != vFirstAdultID && staffVouchers[i].dependant === false) {
            if (staffVouchers[i].vo_amt_req >= vAmt) {
              staffVouchers[i].vo_amt = vAmt;
              vAmt = 0;
            } else if (staffVouchers[i].vo_amt_req < vAmt) {
              staffVouchers[i].vo_amt = staffVouchers[i].vo_amt_req;
              vAmt = vAmt - staffVouchers[i].vo_amt_req;
            }
          }
        } else {
          break;
        }
      }
    }

    //get voucher value of the first adult per seg;

    for (i = 0; i < oSegments.length; i++) {
      vFirstAdult = 0;
      //get voucher allocation of first adult;
      for (j = 0; j < staffVouchers.length; j++) {
        if ((i + 1) === staffVouchers[j].segId && staffVouchers[j].dependant === false) {
          vFirstAdult = staffVouchers[j].vo_amt;
          break;
        }
      }
      //set voucher allocation of first adult to all the dependants;

      for (j = 0; j < staffVouchers.length; j++) {
        if ((i + 1) === staffVouchers[j].segId && staffVouchers[j].dependant === true) {
          staffVouchers[j].vo_amt = vFirstAdult;
        }
      }
    }

    //Calculate the unpaid
    var unpaid = 0;
    for (i = 0; i < staffVouchers.length; i++) {
      unpaid += (staffVouchers[i].vo_amt_req - staffVouchers[i].vo_amt);
    }

    result = unpaid;
    // console.log(result);

    return result;
  }

  function getAmount() {
    // returns tickt amount.
    var oSegments = globalData.getBookingSegments();
    var bkgSale = globalData.getBookingSaleData();
    var iCount = 0;
    var result = 0;
    var paxNum = 0;
    var vFirstAdultAmt = 0;
    var vFirstAdultNum = 0; // number for vouchers required for first adult;

    // console.log("getAmount= ");
    // console.log(bkgSale);

    if ($scope.bkgType === 'B' || IsPALSBeyond() === true) {
      for (iCount = 0; iCount < oSegments.length; iCount++) {
        vFirstAdultAmt += (parseFloat(oSegments[iCount].fare) / 100);
      }
      vFirstAdultAmt = vFirstAdultAmt.toFixed(2);

      vFirstAdultNum = (vFirstAdultAmt / $scope.voucherUnitValue);

      // console.log("vouchernumber = " + $scope.voucherNumber);
      // console.log("vFirstAdultNum = " + vFirstAdultNum);
      // console.log("Booking type = " + $scope.bkgType);
      // console.log("Names =");
      // console.log(bkgSale.names);

      if ($scope.voucherNumber < vFirstAdultNum) {
        // console.log("part 1");
        paxNum = bkgSale.names.length;
      } else {
        // console.log("part 2");
        if ($scope.bkgType === 'B') {
          for (iCount = 0; iCount < bkgSale.names.length; iCount++) {
            //if (bkgSale.names[iCount].dependant === false){
            paxNum += 1;
            //}
          }
        } else {
          for (iCount = 0; iCount < bkgSale.names.length; iCount++) {
            if (bkgSale.names[iCount].dependant === false) {
              paxNum += 1;
            }
          }
        }
      }
    } else {
      paxNum = bkgSale.names.length;
    }

    for (iCount = 0; iCount < oSegments.length; iCount++) {
      result += (parseFloat(oSegments[iCount].fare) / 100);
    }
    result = (result * paxNum);
    // console.log("getAmount = " + result);
    return result;
  }

  //#####################################################################################
  //Send data to e-Tap
  //#####################################################################################

  function SendEtap() {
    //var sUrl = "https://api-dev.eurostar.com/test/etap/v2/bookings/auth/authenticate?pos=GBELG";
    //var hdrData = {"apikey":"e1f55aa8095f43f2b26fc0d54c1f44b3", "cid":"e1f55aa8095f43f2b26fc0d54c1f44"};
    var mres1 = RESOURCES.HOST;
    var sUrl = mres1 + 'api/etap/authenticate?pos=' + RESOURCES.ETAP_POS;
    var hdrData = {
      'cid': RESOURCES.ETAP_CID
    };
    //  console.log(sUrl);
    //  console.log(hdrData);
    openModal();
    eTapServices.authenticate($scope.pnr, $scope.paxNames[0].surName, sUrl, hdrData)
      .then(function (res) {
        if (res.messages) {
          if (res.messages.length > 0) {
            for (var i = 0; i < res.messages.length; i++) {
              if (res.messages[i].level === 'ERROR') {
                if (res.messages[i].message) {
                  //writeTechAudit(res.messages[i].message);
                  etapWarning();
                  closeModal();
                }
              }
            }
          }
        }
        if (res.accessToken && res.booking) {
          $scope.eTapAuthRsp = res;
          //  console.log ($scope.eTapAuthRsp);
          //  result = true;
          if (res.booking.detailsCaptured) {
            // if (res.booking.detailsCaptured === "NOT_CAPTURED") {
            UpdatePaxETAP();
            // }
          }
        } else {
          etapWarning();
          closeModal();
        }
      }, function () {
        etapWarning();
        closeModal();
      });
  }

  function UpdatePaxETAP() {
    var i = 0;
    var j = 0;
    for (i = 0; i < $scope.eTapAuthRsp.booking.passengers.length; i++) {
      for (j = 0; j < $scope.paxNames.length; j++) {
        if (($scope.eTapAuthRsp.booking.passengers[i].firstName.toUpperCase() === $scope.paxNames[j].firstName.toUpperCase()) &&
          ($scope.eTapAuthRsp.booking.passengers[i].lastName.toUpperCase() === $scope.paxNames[j].surName.toUpperCase())) {

          $scope.paxNames[j].etap_id = $scope.eTapAuthRsp.booking.passengers[i].id;
          $scope.paxNames[j].etap_cin = $scope.eTapAuthRsp.booking.passengers[i].cin;
          break;
        }
      }
    }
    sendPaxUpdate(0);
  }

  function etapWarning() {
    var elgData = globalData.getElgBkgData();
    if (!elgData.tnr.tnr_msg) {
      elgData.tnr.tnr_msg = [];
    }
    var msg = {
      'msg_text': 'Update Failed - Use Manage Your Booking to print tickets'
    };
    elgData.tnr.tnr_msg.push(msg);
    globalData.setElgBkgData(elgData);
    $location.path('/retrieve');
  }

  function sendPaxUpdate(idx) {
    var sUrl = '';
    var hdrData = {};
    var data = {};
    var paxId = '';
    var hasErr = false;
    // console.log("passenger id = " + idx);
    // console.log("passenger length = " + $scope.paxNames.length);
    // console.log($scope.paxNames);

    if (idx < $scope.paxNames.length) {
      paxId = $scope.paxNames[idx].etap_id;
      //sUrl = "https://api-dev.eurostar.com/test/etap/v2/bookings/" + $scope.pnr + "/passengers/" + paxId;
      //hdrData = {"apikey":"e1f55aa8095f43f2b26fc0d54c1f44b3", "authorization": $scope.eTapAuthRsp.accessToken.token, "cid":"Elgar1"};

      sUrl = RESOURCES.ETAP_PATH + $scope.pnr + '/passengers/' + paxId;
      hdrData = {
        'authorization': $scope.eTapAuthRsp.accessToken.token,
        'cid': RESOURCES.ETAP_UPDATE_CID
      };

      if ($scope.paxNames[idx].etap_email != '' && $scope.paxNames[idx].etap_phone != '') {
        data = {
          'cin': $scope.paxNames[idx].cin,
          'email': $scope.paxNames[idx].etap_email,
          'optIn': true,
          'phone': {
            'countryCode': $scope.paxNames[idx].etap_phone_dial.toString(),
            'number': $scope.paxNames[idx].etap_phone.toString()
          }
        };
      } else {
        if ($scope.paxNames[idx].etap_email != '' && $scope.paxNames[idx].etap_phone === '') {
          data = {
            'cin': $scope.paxNames[idx].cin,
            'email': $scope.paxNames[idx].etap_email,
            'optIn': true
          };
        } else if ($scope.paxNames[idx].etap_email === '' && $scope.paxNames[idx].etap_phone != '') {
          data = {
            'cin': $scope.paxNames[idx].cin,
            'optIn': true,
            'phone': {
              'countryCode': $scope.paxNames[idx].etap_phone_dial.toString(),
              'number': $scope.paxNames[idx].etap_phone.toString()
            }
          };
        }
      }
      // console.log("")
      // console.log(data);

      eTapServices.updatePax(data, sUrl, hdrData)
        .then(function (res) {
          if (res.messages) {
            if (res.messages.length > 0) {
              for (var i = 0; i < res.messages.length; i++) {
                if (res.messages[i].level === 'ERROR') {
                  if (res.messages[i].message) {
                    //there is errror, ignore passbook or PDF ticket and advice user to ticket usig manage my booking.
                    // etapWarning();
                    // closeModal();
                    hasErr = true;
                  }
                }
              }
            }
          }
          if (hasErr === false) {
            if (res.passenger) {
              idx = idx + 1;
              sendPaxUpdate(idx);
            }
          } else {
            etapWarning();
            closeModal();
          }

        }, function () {
          etapWarning();
          closeModal();
        });
    } else {
      //ETAP Update is done without errors now proceed to PDF or PASSBOOK Ticket.
      if (($scope.bkgType === 'B') || ($scope.bkgType === 'I') || ($scope.bkgType === 'M') || ($scope.bkgType === 'F') || ($scope.bkgType === 'T') || ($scope.bkgType === 'P' && HaveDirectServices() === true)) {
        //eTapTicket(0);
        sendCreateInfant(0);
      } else {
        $location.path('/retrieve');
      }

    }
  }

  function sendCreateInfant(idx) {
    var sUrl = '';
    var hdrData = {};
    var data = {};
    var hasErr = false;

    // console.log("infant id = " + idx);
    // console.log("infant length = " + $scope.paxNames.length);

    if (idx < $scope.paxNames.length) {
      if ($scope.paxNames[idx].etap_Infant_Fname != '' && $scope.paxNames[idx].etap_Infant_Sname != '') {

        // console.log("First Name = " + $scope.paxNames[idx].etap_Infant_Fname + " Last Name = " + $scope.paxNames[idx].etap_Infant_Sname);

        sUrl = RESOURCES.ETAP_PATH + $scope.pnr + '/infant?pos='+RESOURCES.ETAP_POS;
        hdrData = {
          'authorization': $scope.eTapAuthRsp.accessToken.token,
          'cid': RESOURCES.ETAP_UPDATE_CID
        };
        data = {
          'guardianId': $scope.paxNames[idx].etap_id,
          'firstName': $scope.paxNames[idx].etap_Infant_Fname,
          'lastName': $scope.paxNames[idx].etap_Infant_Sname
        };

        eTapServices.createInfant(data, sUrl, hdrData)
          .then(function (res) {
            if (res.messages) {
              if (res.messages.length > 0) {
                for (var i = 0; i < res.messages.length; i++) {
                  if (res.messages[i].level === 'ERROR') {
                    if (res.messages[i].message) {
                      // writeTechAudit(res.messages[i].message);
                      // etapWarning();
                      // closeModal();
                      hasErr = true;
                    }
                  }
                }
              }
            }

            if (hasErr === false) {
              if (res.infant) {
                idx = idx + 1;
                sendCreateInfant(idx);
              }
            } else {
              etapWarning();
              closeModal();
            }
          }, function () {
            //send error to writeAudit
            etapWarning();
            closeModal();

            // if (err){
            //   if(err.messages) {
            //     if (err.messages.length > 0){
            //       for(var i=0;i<err.messages.length;i++){
            //         if (err.messages[i].level === 'ERROR'){
            //           if(err.messages[i].message){
            //           // writeTechAudit(err.messages[i].message);
            //           }
            //         }
            //       }
            //     }
            //   }
            //   else {
            //   // writeTechAudit("Unknown e-Tap Error");
            //   }
            // }
            // else {
            //   // writeTechAudit("Unknown e-Tap Error");
            // }
            // idx = idx + 1;
            // sendCreateInfant(idx);
          });
      } else {
        idx = idx + 1;
        sendCreateInfant(idx);
      }
    } else {
      $location.path('/retrieve');
      closeModal();
    }
  }

  function isBkgABS() {
    var result = false;
    var booking = globalData.getElgBkgData();
    for (var i = 0; i < booking.tnr.tnr_tkts.length; i++) {
      if (booking.tnr.tnr_tkts[i].tktd_fare_type === 'B') {
        result = true;
        break;
      }
    }
    return result;
  }
});
