import app from '../app';
import { closeModal, convertDate, nonceGenerator, openModal, s3_ProcessPaxInfo } from '../utils';
import moment from 'moment';

app.controller('AftersalesController', function ($scope, $rootScope, $location, RESOURCES, globalData, availabilityService, afterSalesService, md5, $translate) {
  init();

  // *********************************************************************************************************************
  // Special Rule for DUTY Bookings if segment booked in Business premier and traveling after 0800 hrs and before 2000 hrs.
  // Exchanged segment will be booked in HF instead of AF.
  // *********************************************************************************************************************

  function init() {
    console.log("AftersalesController - init()" );
    $scope.failedMealRequest = false;
    $scope.dutyOBCosChange = false;
    $scope.dutyIBCosChange = false;

    if (globalData.getAfterSaleFlag() == 'E') {
      $scope.showExchange = true;
      $scope.showCancel = false;
    } else if (globalData.getAfterSaleFlag() == 'C') {
      $scope.showExchange = false;
      $scope.showCancel = true;
    }

    $scope.Booking = globalData.getElgBkgData();
    $scope.travelDate = [];
    $scope.disableConfirm = true;

    $scope.currency = getBookingCurrency($scope.Booking); //$rootScope.loggedInUser.ARIA_User.Default_Currency;

    $scope.bookingType = $scope.Booking.tnr.tnr_booking_type; // Read Booking Type 'D' == Duty, 'P' = Pals etc.
    $scope.isER = $scope.bookingType === 'R';
    $scope.isBlue = $scope.bookingType === 'B';

    if ($scope.isBlue) {
      $scope.disableConfirm = false;
    }

    $scope.milesUsed = 0;
    $scope.milesToBeRefunded = 0;
    $scope.blueVouchersToBeRefunded = 0;
    $scope.cashToBeRefunded = 0;

    $scope.Booking.payments.forEach(p => {
      if (p.method === 'Blue_Voucher') {
	$scope.blueVouchersToBeRefunded += parseFloat(p.reference.split('-')[0]);
      } else if (p.method === 'CC') {
	$scope.cashToBeRefunded += parseFloat(p.availableRefundAmount);
      }
    });

    $scope.ccPayments = $scope.Booking.payments.filter(p => p.method === 'CC');

    $scope.today = new Date(); //current date to stop date components displaying previous dates
    $scope.maxDate = new Date();

    $scope.bookingHorizon = getMaxDays();
    //console.log("bookingHorizon = " + $scope.bookingHorizon);

    $scope.maxDate = $scope.maxDate.setDate($scope.today.getDate() + $scope.bookingHorizon);

    for (var i = 0; i < $scope.Booking.tnr.tnr_segments.length; i++) {
      $scope.travelDate[i] = moment($scope.Booking.tnr.tnr_segments[i].segt_dep_date_full).toDate();
      if ($scope.showCancel == true) {
        $scope.Booking.tnr.tnr_segments[i].isChecked = false;
      }
    }

    $scope.afterSaleSegs = {
      id: $rootScope.loggedInUser.ARIA_User.Aria_User_Name,
      locator: $scope.Booking.tnr.tnr_ers_pnr,
      outBound: {
        origin: '',
        destination: '',
        carrier: '',
        train_number: '',
        dep_date: '',
        ccos: ''
      },
      inBound: {
        origin: '',
        destination: '',
        carrier: '',
        train_number: '',
        dep_date: '',
        ccos: ''
      }
    };
    //console.log($scope.Booking);

    getSSR($scope.Booking);

    $scope.TicketsToCancel = $scope.Booking.tnr.tnr_tkts
          .filter(tkt => tkt.tktd_status === 1)
          .map(tkt => {
            let seg = $scope.Booking.tnr.tnr_segments.find(seg => seg.segt_num === tkt.tktd_seg_nums[0]);
            let paxName = $scope.Booking.tnr.tnr_names.find(name => name.s3_passenger_id === tkt.s3_tktd_passenger_id);

            return {
              ...seg,
              passengerName: `${paxName.name_initials} ${paxName.name_surname}`,
              tkt
            };
          });
  }

  function getMaxDays() {
    var h1 = 0;
    var h2 = 0;

    // h1 = globalData.getStationHorizon($scope.Booking.tnr.tnr_segments[0].segt_dep_station_full_name.trim());
    // h2 = globalData.getStationHorizon($scope.Booking.tnr.tnr_segments[0].segt_arr_station_full_name.trim());
    h1 = globalData.getStationHorizonByCode($scope.Booking.tnr.tnr_segments[0].segt_dep_station.trim());
    h2 = globalData.getStationHorizonByCode($scope.Booking.tnr.tnr_segments[0].segt_arr_station.trim());

    if (h1 <= h2) {
      return h1;
    } else if (h2 <= h1) {
      return h2;
    }
  }

  function getBookingCurrency(response) {
    var result = $rootScope.loggedInUser.ARIA_User.Default_Currency;
    if (response) {
      if (response.tnr) {
        if (response.tnr.tnr_tkts) {
          for (var i = 0; i < response.tnr.tnr_tkts.length; i++) {
            if (response.tnr.tnr_tkts[i].tktd_status == 1) {
              result = response.tnr.tnr_tkts[i].tktd_currency;
              break;
            }
          }
        }
      }
    }
    return result;
  }

  $scope.ShowFullDate = function (data) {
    return globalData.getFullDate($scope.bookingHorizon, data, '00:00');
  };

  $scope.readKey = function (event) {
    var x = event.which || event.keyCode;
    //block all keypress except tab.
    if (x != 9) {
      event.preventDefault();
    }
  };

  $scope.showValidForExch = function (item) {
    var result = false;
    //console.log("showValidForExch");
    //console.log(item);
    //console.log($scope.bookingType);
    if (item) {
      if ($scope.bookingType == 'D') {
        result = true;
      } else if ($scope.bookingType == 'F' || $scope.bookingType == 'B') {
        if (globalData.isPastDate(item.segt_dep_date, item.segt_dep_time) == false) {
          result = true;
        }
      }
    }
    //console.log("showValidForExch : " + result);
    return result;
  };

  $scope.showValidForCan = function (item) {
    var result = false;
    //console.log("showValidForCan");
    //console.log(item);
    //console.log($scope.bookingType);
    if (item) {
      if ($scope.bookingType == 'D' || $scope.bookingType == 'R' || $scope.bookingType == 'B') {
        if (globalData.isPastDate(item.segt_dep_date, item.segt_dep_time) == false) {
          result = true;
        }
      }
    }
    //console.log("showValidForCan : " + result);
    return result;
  };

  function toggleChevron(e) {
    $(e.target)
      .prev('.panel-heading')
      .find('i.indicator')
      .toggleClass('glyphicon-chevron-down glyphicon-chevron-up');
  }

  $scope.clearTrains = function () {
    $scope.trains = [];
    $scope.requestError = '';
  };

  $('#accordion').on('hidden.bs.collapse', toggleChevron);
  $('#accordion').on('shown.bs.collapse', toggleChevron);

  $scope.findAvailability = function (item, tDate, index) {
    $scope.trains = [];
    $scope.requestError = '';
    $scope.segmentFareType = item.segt_class.toUpperCase();
    $scope.segmentFareType_desc = item.segt_class_desc.toUpperCase();
    var mres1 = RESOURCES.HOST;

    var bkgType = $scope.Booking.tnr.tnr_booking_type;
    var paxNum = $scope.Booking.tnr.tnr_names.length;
    var origin = item.segt_dep_station;
    var destination = item.segt_arr_station;
    var dateOfTravel = convertDate(tDate);
    let exchangeItems = item.products.map(product => Number(product.itemRef));
    let bookingRef = $scope.Booking.tnr.tnr_num;

    if (!bkgType) {
      $translate('Text262').then(function (txtMsg) {
        $scope.requestError = txtMsg;
      });
    }
    if (!paxNum) {
      $translate('Text263').then(function (txtMsg) {
        $scope.requestError = txtMsg;
      });
    } else if (paxNum <= 0) {
      $translate('Text263').then(function (txtMsg) {
        $scope.requestError = txtMsg;
      });
    }
    if (!origin) {
      $translate('Text264').then(function (txtMsg) {
        $scope.requestError = txtMsg;
      });
    }
    if (!destination) {
      $translate('Text265').then(function (txtMsg) {
        $scope.requestError = txtMsg;
      });
    }
    if (!dateOfTravel) {
      $translate('Text266').then(function (txtMsg) {
        $scope.requestError = txtMsg;
      });
    }
    if (index > 0) {
      $scope.isReturn = true;
      if ($scope.travelDate[index] < $scope.travelDate[0]) {
        $scope.requestError = true;

        $translate('Text269').then(function (txtMsg) {
          $scope.requestError = txtMsg;
        });
      }
    } else {
      $scope.isReturn = false;

      if ($scope.travelDate.length > 1 && $scope.travelDate[0] > $scope.travelDate[1]) {
        $scope.requestError = true;

        $translate('Text269').then(function (txtMsg) {
          $scope.requestError = txtMsg;
        });
      }
    }

    if (!$scope.requestError) {
      openModal();

      var nnce = nonceGenerator();
      var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
      var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

      availabilityService.getExchangeFareAvailability(exchangeItems, bookingRef, userid, rndString, nnce, bkgType, paxNum, origin, destination, dateOfTravel, mres1, $scope.currency)
        .then(function (res) {
          //Check if there is an error.
          if (res.wsi_error) {

            $scope.trains = {};

            if (res.wsi_error.err_ers_text) {
              $scope.requestError = res.wsi_error.err_ers_text;
            } else {
              $scope.requestError = res.wsi_error.err_wsi_text;
            }
            closeModal();
            $scope.rspSuccess = false;
          } else {
            //if no error then process the response.
            $scope.trains = res;
            if ($scope.trains.FareAvailabilityServiceOut.solutions_out) {
              $scope.requestError = '';
              $scope.rspSuccess = true;
              filterAMSBXS();
              filterThCarrier();
            } else {
              $translate('Text267').then(function (txtMsg) {
                $scope.requestError = txtMsg;
              });
              //$scope.requestError = "No trains available for the fare type."
              $scope.rspSuccess = false;
            }
            closeModal(); //Close the busy indicator.
          }
        }, function (err) {
        });

    }
  };

  function filterAMSBXS() {
    var i = 0;
    var dataFound = false;

    while (i < $scope.trains.FareAvailabilityServiceOut.solutions_out.length) {
      dataFound = false;

      if (($scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].dep_station.toUpperCase() === 'NLAMA' && $scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].arr_station.toUpperCase() === 'BEBMI') ||
        ($scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].dep_station.toUpperCase() === 'BEBMI' && $scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].arr_station.toUpperCase() === 'NLAMA')) {
        if ($scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].carrier === 'SB' ||
          $scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].carrier === 'NS' ||
          $scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].carrier === 'TH') {
          dataFound = true;
        }
      }
      if (dataFound === true) {
        $scope.trains.FareAvailabilityServiceOut.solutions_out.splice(i, 1);
      } else {
        i = i + 1;
      }
    }
  }

  function filterThCarrier() {
    var i = 0;
    var dataFound = false;
    while (i < $scope.trains.FareAvailabilityServiceOut.solutions_out.length) {
      dataFound = false;

      if ($scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].carrier === 'TH') {
        dataFound = true;
      }

      if (dataFound === true) {
        $scope.trains.FareAvailabilityServiceOut.solutions_out.splice(i, 1);
      } else {
        i = i + 1;
      }
    }
  }

  $scope.checkFare = function (item) {
    var result = false;
    for (var i = 0; i < item.fares.length; i++) {
      for (var j = 0; j < item.fares[i].ccos.length; j++) {
        // *********************************************************************************************************************
        // Special Rule for DUTY Bookings if segment booked in Business premier and traveling after 0800 hrs and arriving 2000 hrs.
        // Exchanged segment will be booked in HF instead of AF
        // *********************************************************************************************************************

        if (($scope.bookingType == 'D') && ($scope.segmentFareType == 'AF') && (parseInt(item.legs[0].dep_time) > parseInt('0800') && parseInt(item.legs[0].arr_time) < parseInt('2000'))) {
          if (item.fares[i].ccos[j] == 'HT') {
            result = true;
            break;
          }
        } else {
          // For non Duty booking, exchange is valid withing same class of service
          if ($scope.segmentFareType.substr(0, 1) == item.fares[i].ccos[j].substr(0, 1)) {
            result = true;
            break;
          }
        }
      }
    }
    //Check if the aftersale is same day return, restrict the  trains which are after 1 hours time from the arrival time.
    if ($scope.isReturn == true) {

      if (result == true) {
        var d1 = '';
        var t1 = '';

        if (($scope.afterSaleSegs.outBound.origin) && ($scope.afterSaleSegs.outBound.destination) && ($scope.afterSaleSegs.outBound.train_number) && ($scope.afterSaleSegs.outBound.dep_date)) {
          d1 = moment($scope.afterSaleSegs.outBound.dep_date_full).toDate();
          t1 = $scope.afterSaleSegs.outBound.arr_time;
        } else {
          d1 = moment($scope.Booking.tnr.tnr_segments[0].segt_dep_date_full).toDate();
          t1 = $scope.Booking.tnr.tnr_segments[0].segt_arr_time;
        }

        var d2 = $scope.travelDate[1];
        var t2 = item.legs[0].dep_time.substring(0, 2) + ':' + item.legs[0].dep_time.substring(2, 4);

        if (globalData.compareDates(d1, t1, d2, t2) == false) {
          result = false;
        } else {
          result = true;
        }
      }
    }

    return result;
  };

  $scope.AddJourney = function (train, segNo, index, segment_Direction) {
    var jCount = 0;
    var ccos_set = false;
    var month = [];

    month[0] = 'January';
    month[1] = 'February';
    month[2] = 'March';
    month[3] = 'April';
    month[4] = 'May';
    month[5] = 'June';
    month[6] = 'July';
    month[7] = 'August';
    month[8] = 'September';
    month[9] = 'October';
    month[10] = 'November';
    month[11] = 'December';

    if (index == 0) {
      $scope.afterSaleSegs.outBound.origin = train.legs[0].dep_station;
      $scope.afterSaleSegs.outBound.origin_full = globalData.getStationName(train.legs[0].dep_station);
      $scope.afterSaleSegs.outBound.destination = train.legs[0].arr_station;
      $scope.afterSaleSegs.outBound.destination_full = globalData.getStationName(train.legs[0].arr_station);
      $scope.afterSaleSegs.outBound.carrier = train.legs[0].carrier;
      $scope.afterSaleSegs.outBound.train_number = train.legs[0].train_number;
      $scope.afterSaleSegs.outBound.dep_date = convertDate($scope.travelDate[index]);
      $scope.afterSaleSegs.outBound.dep_date_full = $scope.travelDate[index].getDate() + ' ' + month[$scope.travelDate[index].getMonth()] + ' ' + $scope.travelDate[index].getFullYear();
      $scope.afterSaleSegs.outBound.dep_time = train.legs[0].dep_time.substring(0, 2) + ':' + train.legs[0].dep_time.substring(2, 4);
      $scope.afterSaleSegs.outBound.arr_time = train.legs[0].arr_time.substring(0, 2) + ':' + train.legs[0].arr_time.substring(2, 4);

      $scope.afterSaleSegs.outBound.s3_direction = segment_Direction; //"outbound";
      $scope.afterSaleSegs.outBound.s3_service_identifier =  train.legs[0].s3_service_identifier;
      $scope.afterSaleSegs.outBound.s3_items = [];

      train.fares.forEach(fare => {
        if($scope.segmentFareType_desc.toUpperCase() === fare.type.toUpperCase()){
          $scope.trains.FareAvailabilityServiceOut.s3_passengers.forEach(passenger => {
            fare.code.filter(fc => passenger.id === fc.passengerId).forEach(tCode => {
              let item = { passengerId: '', tariffCode: '' };
              item.passengerId = passenger.id;
              item.tariffCode = tCode.tariffCode;
              $scope.afterSaleSegs.outBound.s3_items.push(item);
            });
          });
        }
      });

      if (($scope.bookingType == 'D') && ($scope.segmentFareType == 'AF') && (parseInt(train.legs[0].dep_time) > parseInt('0800') && parseInt(train.legs[0].arr_time) < parseInt('2000'))) {
        $scope.dutyOBCosChange = true;
        $scope.afterSaleSegs.outBound.ccos = 'HT';
        $scope.afterSaleSegs.outBound.ccos_desc = 'STANDARD PREMIER';
      } else {
        $scope.dutyOBCosChange = false;
        ccos_set = false;
        for (var i = 0; (i < train.fares.length) && (ccos_set == false); i++) {
          for (var j = 0; (j < train.fares[i].ccos.length) && (ccos_set == false); j++) {
            if ($scope.segmentFareType.substr(0, 1) == train.fares[i].ccos[j].substr(0, 1)) {
              $scope.afterSaleSegs.outBound.ccos = train.fares[i].ccos[j];
              ccos_set = true;
            }
          }
        }
        $scope.afterSaleSegs.outBound.ccos_desc = $scope.segmentFareType_desc;
      }

      $scope.afterSaleSegs.outBound.segno = segNo;
    }
    else
    {
      $scope.afterSaleSegs.inBound.origin = train.legs[0].dep_station;
      $scope.afterSaleSegs.inBound.origin_full = globalData.getStationName(train.legs[0].dep_station);
      $scope.afterSaleSegs.inBound.destination = train.legs[0].arr_station;
      $scope.afterSaleSegs.inBound.destination_full = globalData.getStationName(train.legs[0].arr_station);
      $scope.afterSaleSegs.inBound.carrier = train.legs[0].carrier;
      $scope.afterSaleSegs.inBound.train_number = train.legs[0].train_number;
      $scope.afterSaleSegs.inBound.dep_date = convertDate($scope.travelDate[index]);

      $scope.afterSaleSegs.inBound.s3_direction = segment_Direction; //"inbound";
      $scope.afterSaleSegs.inBound.s3_service_identifier =  train.legs[0].s3_service_identifier;
      $scope.afterSaleSegs.inBound.s3_items = [];

      train.fares.forEach(fare => {
        if ($scope.segmentFareType_desc.toUpperCase() === fare.type.toUpperCase()) {
          $scope.trains.FareAvailabilityServiceOut.s3_passengers.forEach(passenger => {
            fare.code.filter(fc => passenger.id === fc.passengerId).forEach(tCode => {
              let item = { passengerId : '', tariffCode : '' };
              item.passengerId = passenger.id;
              item.tariffCode = tCode.tariffCode;
              $scope.afterSaleSegs.inBound.s3_items.push(item);
            });
          });
        }
      });

      $scope.afterSaleSegs.inBound.dep_date_full = $scope.travelDate[index].getDate() + ' ' + month[$scope.travelDate[index].getMonth()] + ' ' + $scope.travelDate[index].getFullYear();
      $scope.afterSaleSegs.inBound.dep_time = train.legs[0].dep_time.substring(0, 2) + ':' + train.legs[0].dep_time.substring(2, 4);
      $scope.afterSaleSegs.inBound.arr_time = train.legs[0].arr_time.substring(0, 2) + ':' + train.legs[0].arr_time.substring(2, 4);

      if (($scope.bookingType == 'D') && ($scope.segmentFareType == 'AF') && (parseInt(train.legs[0].dep_time) > parseInt('0800') && parseInt(train.legs[0].arr_time) < parseInt('2000'))) {
        $scope.dutyIBCosChange = true;
        $scope.afterSaleSegs.inBound.ccos = 'HT';
        $scope.afterSaleSegs.inBound.ccos_desc = 'STANDARD PREMIER';
      } else {
        $scope.dutyIBCosChange = false;
        ccos_set = false;
        for (var i = 0;
          (i < train.fares.length) && (ccos_set == false); i++) {
          for (var j = 0;
            (j < train.fares[i].ccos.length) && (ccos_set == false); j++) {
            if ($scope.segmentFareType.substr(0, 1) == train.fares[i].ccos[j].substr(0, 1)) {
              $scope.afterSaleSegs.inBound.ccos = train.fares[i].ccos[j];
              ccos_set = true;
            }
          }
        }
        $scope.afterSaleSegs.inBound.ccos_desc = $scope.segmentFareType_desc;
      }

      $scope.afterSaleSegs.inBound.segno = segNo;
    }

    if (($scope.afterSaleSegs.outBound.origin != '') && ($scope.afterSaleSegs.outBound.destination != '') && ($scope.afterSaleSegs.outBound.carrier != '') && ($scope.afterSaleSegs.outBound.train_number != '') && ($scope.afterSaleSegs.outBound.dep_date != '') && ($scope.afterSaleSegs.outBound.ccos != '')) {
      $scope.showout = true;
    } else {
      $scope.showout = false;
    }
    if (($scope.afterSaleSegs.inBound.origin != '') && ($scope.afterSaleSegs.inBound.destination != '') && ($scope.afterSaleSegs.inBound.carrier != '') && ($scope.afterSaleSegs.inBound.train_number != '') && ($scope.afterSaleSegs.inBound.dep_date != '') && ($scope.afterSaleSegs.inBound.ccos != '')) {
      $scope.showin = true;
    } else {
      $scope.showin = false;
    }

    $scope.trains = {};

    if (($scope.dutyOBCosChange == true) || ($scope.dutyIBCosChange == true)) {
      $translate('Text268').then(function (txtMsg) {
        $scope.dutyWarningMsg = txtMsg;
      });
    }
  };

  function checkJourney() {
    // return true if date is valid and false if date is invalid.
    //console.log("checkJourney");
    var result = true;

    if ($scope.showin == false) {
      if ($scope.Booking.tnr.tnr_segments.length > 1 && $scope.afterSaleSegs.outBound.segno === 1) {
        var d1 = globalData.getFullDate($scope.bookingHorizon, $scope.afterSaleSegs.outBound.dep_date);
        var d2 = globalData.getFullDate($scope.bookingHorizon, $scope.Booking.tnr.tnr_segments[1].segt_dep_date);
        //  console.log("Date 1 " + d1);
        // console.log("Date 2 " + d2);
        // console.log("Greater Date : " + d1 > d2);
        if (d1 > d2) {
          result = false;
        }
      }
    }
    return result;
  }

  $scope.cancelExchange = function () {
    $scope.exchangeError = '';
    $scope.showin = false;
    $scope.showout = false;
    $scope.failedMealRequest = false;
    var xRequest = generateExchange(true);
    globalData.setRefCode(xRequest.locator);
    $location.path('/retrieve');
  };

  $scope.ExchangeWoMeal = function () {
    var result = true;
    result = checkJourney();

    if (result == true) {

      openModal();
      var xRequest = generateExchange(true);
      //console.log(xRequest);
      var mres1 = RESOURCES.HOST;

      afterSalesService.exchangeBooking(xRequest, mres1)
        .then(function (res) {
          //Check if there is an error.
          if (res.wsi_error) {
            if (res.wsi_error.err_ers_text) {
              $scope.exchangeError = res.wsi_error.err_ers_text;
              $scope.showin = false;
              $scope.showout = false;
              $scope.failedMealRequest = false;
            } else {
              $scope.exchangeError = res.wsi_error.err_wsi_text;
              $scope.showin = false;
              $scope.showout = false;
              $scope.failedMealRequest = false;
            }
            closeModal();
            //console.log ("Exchange error:- " + $scope.exchangeError);
          } else {
            $scope.failedMealRequest = false;
            //if Success then process the response.
            res.ExchBookingServiceOut = s3_ProcessPaxInfo(res.ExchBookingServiceOut);
            globalData.setElgBkgData(res.ExchBookingServiceOut);
            globalData.setRefCode(xRequest.locator);
            $location.path('/retrieve');
            closeModal(); //Close the busy indicator.
          }
        }, function (err) {
          //closeModal();           //Close the busy indicator.
          //console.log("Exchange failed");
        });

    } else {
      $translate('Text269').then(function (txtMsg) {
        $scope.exchangeError = txtMsg;
      });
      //$scope.exchangeError = "Outbound journey date is greater than the return date."
    }
  };

  $scope.Exchange = function () {
    var result = true;

    result = checkJourney();

    if (result == true) {

      openModal();
      var xRequest = generateExchange(false);
      //console.log(xRequest);

      var mres1 = RESOURCES.HOST;

      afterSalesService.exchangeBooking(xRequest, mres1)
        .then(function (res) {
          //Check if there is an error.
          if (res.wsi_error) {
            if (res.wsi_error.err_ers_text) {
              $scope.exchangeError = res.wsi_error.err_ers_text;
              if (res.wsi_error.err_ers_nbr === 1604) {
                $scope.failedMealRequest = true;
              } else {
                $scope.showin = false;
                $scope.showout = false;
              }
            } else {
              $scope.exchangeError = res.wsi_error.err_wsi_text;
              if (res.wsi_error.err_wsi_nbr === 60028) {
                $scope.failedMealRequest = true;
              } else {
                $scope.showin = false;
                $scope.showout = false;
              }
            }
            closeModal();
            //console.log ("Exchange error:- " + $scope.exchangeError);
          } else {
            //if Success then process the response.
            res.ExchBookingServiceOut = s3_ProcessPaxInfo(res.ExchBookingServiceOut);
            globalData.setElgBkgData(res.ExchBookingServiceOut);
            globalData.setRefCode(xRequest.locator);
            $location.path('/retrieve');
            closeModal(); //Close the busy indicator.
          }
        }, function (err) {
          //closeModal();           //Close the busy indicator.
          //console.log("Exchange failed");
        });

    } else {
      $translate('Text269').then(function (txtMsg) {
        $scope.exchangeError = txtMsg;
      });
      //$scope.exchangeError = "Outbound journey date is greater than the return date."
    }
  };

  function generateExchange(excludeSSR) {
    var result = {
      id: '',
      locator: '',
      can_segs: [],
      new_segs: []
    };
    var newSeg = {};
    var i = 0;

    var nnce = nonceGenerator();
    var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

    result.Aria_User_Name = userid;
    result.Aria_Passcode = rndString;
    result.Client_Tranx_Id = nnce;

    result.id = $scope.afterSaleSegs.id;
    result.locator = $scope.afterSaleSegs.locator;
    result.currency = $scope.currency;

    if ($scope.showout == true) {
      result.can_segs.push($scope.afterSaleSegs.outBound.segno);
      newSeg = {
        origin: $scope.afterSaleSegs.outBound.origin,
        destination: $scope.afterSaleSegs.outBound.destination,
        carrier: $scope.afterSaleSegs.outBound.carrier,
        train_number: $scope.afterSaleSegs.outBound.train_number,
        dep_date: $scope.afterSaleSegs.outBound.dep_date,
        ccos: $scope.afterSaleSegs.outBound.ccos,
        s3_direction: $scope.afterSaleSegs.outBound.s3_direction,
        s3_service_identifier: $scope.afterSaleSegs.outBound.s3_service_identifier,
        s3_items: $scope.afterSaleSegs.outBound.s3_items
      };
      if ($scope.ssr) {
        if (excludeSSR === false) {
          for (i = 0; i < $scope.ssr.length; i++) {
            if ($scope.afterSaleSegs.outBound.segno == $scope.ssr[i].segno) {
              newSeg.tfaxs = $scope.ssr[i].tfax_new;
              break;
            }
          }
        }
      }
      result.new_segs.push(newSeg);
    }
    if ($scope.showin == true) {
      result.can_segs.push($scope.afterSaleSegs.inBound.segno);
      newSeg = {
        origin: $scope.afterSaleSegs.inBound.origin,
        destination: $scope.afterSaleSegs.inBound.destination,
        carrier: $scope.afterSaleSegs.inBound.carrier,
        train_number: $scope.afterSaleSegs.inBound.train_number,
        dep_date: $scope.afterSaleSegs.inBound.dep_date,
        ccos: $scope.afterSaleSegs.inBound.ccos,
        s3_direction: $scope.afterSaleSegs.inBound.s3_direction,
        s3_service_identifier: $scope.afterSaleSegs.inBound.s3_service_identifier,
        s3_items: $scope.afterSaleSegs.inBound.s3_items
      };
      if ($scope.ssr) {
        if (excludeSSR === false) {
          for (i = 0; i < $scope.ssr.length; i++) {
            if ($scope.afterSaleSegs.inBound.segno == $scope.ssr[i].segno) {
              newSeg.tfaxs = $scope.ssr[i].tfax_new;
              break;
            }
          }
        }
      }
      result.new_segs.push(newSeg);
    }
    return result;
  }

  $scope.CancelSegments = function () {

    openModal();
    var xRequest = generateCancel();
    var mres1 = RESOURCES.HOST;

    afterSalesService.exchangeBooking(xRequest, mres1)
      .then(function (res) {
        //Check if there is an error.
        if (res.wsi_error) {
          if (res.wsi_error.err_ers_text) {
            $scope.exchangeError = res.wsi_error.err_ers_text;
          } else {
            $scope.exchangeError = res.wsi_error.err_wsi_text;
          }
          closeModal();
        } else {
          //if Success then process the response.
          if (res.ExchBookingServiceOut) {
            res.ExchBookingServiceOut = s3_ProcessPaxInfo(res.ExchBookingServiceOut);
            globalData.setElgBkgData(res.ExchBookingServiceOut);
            if (res.ExchBookingServiceOut.tnr.tnr_no_itin || res.ExchBookingServiceOut.tnr.tnr_segments.length === 0) {
              $location.path('/home');
            } else {
              globalData.setRefCode(xRequest.locator);
              $location.path('/retrieve');
            }
          }
          closeModal(); //Close the busy indicator.
        }
      }, function (err) {
      });
  };

  function generateCancel() {
    var result = {
      id: '',
      locator: '',
      can_segs: [],
      s3_items_to_cancel:[]
    };

    var nnce = nonceGenerator();
    var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

    result.Aria_User_Name = userid;
    result.Aria_Passcode = rndString;
    result.Client_Tranx_Id = nnce;

    result.id = $scope.afterSaleSegs.id;
    result.locator = $scope.afterSaleSegs.locator;
    result.currency = $scope.currency;

    for (var i = 0; i < $scope.Booking.tnr.tnr_segments.length; i++) {
      if ($scope.Booking.tnr.tnr_segments[i].isChecked == true) {
        result.can_segs.push($scope.Booking.tnr.tnr_segments[i].segt_num);
      }
    }
    result.s3_first_passenger_email = userid;
    $scope.Booking.tnr.tnr_tkts.forEach(tkt => {
      let segmentCancelled = result.can_segs.some(segmentNum=> tkt.tktd_seg_nums.indexOf(segmentNum) >= 0);
      if (tkt.tktd_status === 1 && segmentCancelled) {
        result.s3_items_to_cancel.push(tkt.tktd_tcn);
      }
    });
    return result;
  }

  $scope.update = function () {
    for (var i = 0; i < $scope.Booking.tnr.tnr_segments.length; i++) {
      if ($scope.Booking.tnr.tnr_segments[i].isChecked == true) {
        $scope.disableConfirm = false;
        break;
      } else {
        $scope.disableConfirm = true;
      }
    }
  };

  $scope.updateER = function () {
    $scope.milesToBeRefunded = 0;
    $scope.disableConfirm = true;

    for (var i = 0; i < $scope.Booking.tnr.tnr_tkts.length; i++) {
      if ($scope.Booking.tnr.tnr_tkts[i].isChecked == true) {
        let tkt = $scope.Booking.tnr.tnr_tkts[i];
        let seg = $scope.Booking.tnr.tnr_segments.find(seg => tkt.tktd_seg_nums.includes(seg.segt_num));


        if (seg.segt_class.substring(0, 1) == 'A') {
          $scope.milesToBeRefunded += 2;
        } else if (seg.segt_class.substring(0, 1) == 'H') {
          $scope.milesToBeRefunded += 2;
        } else if (seg.segt_class.substring(0, 1) == 'B') {
          $scope.milesToBeRefunded += 1;
        }

        $scope.disableConfirm = false;
      }
    }
  };

  $scope.getTotalToBeRefundedStr = () => {
    if (globalData.hasBookingCreatedInSameQuater($scope.Booking)) {
      return $scope.milesToBeRefunded;
    } else {
      return 0;
    }
  };

  $scope.getTotalBlueVouchersToBeRefundedStr = () => {
    if (globalData.hasBookingCreatedInSameQuater($scope.Booking)) {
      return $scope.milesToBeRefunded;
    } else {
      return 0;
    }
  };

  $scope.CancelSegmentsER = function () {

    openModal();
    var xRequest = generateCancelER();
    var mres1 = RESOURCES.HOST;

    afterSalesService.exchangeBooking(xRequest, mres1)
        .then(function (res) {
          //Check if there is an error.
          if (res.wsi_error) {
            if (res.wsi_error.err_ers_text) {
              $scope.exchangeError = res.wsi_error.err_ers_text;
            } else {
              $scope.exchangeError = res.wsi_error.err_wsi_text;
            }
            closeModal();
          } else {
            //if Success then process the response.
            if (res.ExchBookingServiceOut) {
              if (globalData.hasBookingCreatedInSameQuater($scope.Booking) && $scope.Booking.tnr.tnr_segments.length > 0 && $scope.Booking.tnr.tnr_tkts.length > 0) {
                console.log(`Updating TSV manually until re-login`);
                $rootScope.loggedInUser.ARIA_User.TSV_Balance = parseInt($rootScope.loggedInUser.ARIA_User.TSV_Balance) + $scope.milesToBeRefunded;
              }

              res.ExchBookingServiceOut = s3_ProcessPaxInfo(res.ExchBookingServiceOut);
              globalData.setElgBkgData(res.ExchBookingServiceOut);
              if (res.ExchBookingServiceOut.tnr.tnr_no_itin || res.ExchBookingServiceOut.tnr.tnr_segments.length === 0) {
                $location.path('/home');
              } else {
                globalData.setRefCode(xRequest.locator);
                $location.path('/retrieve');
              }
            }
            closeModal(); //Close the busy indicator.
          }
        }, function (err) {
        });
  };

  function generateCancelER() {
    var result = {
      id: '',
      locator: '',
      isER: $scope.isER,
      can_segs: [],
      s3_items_to_cancel:[],
      miles_used: 0,
      is_in_same_quarter: false
    };

    var nnce = nonceGenerator();
    var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

    result.Aria_User_Name = userid;
    result.Aria_Passcode = rndString;
    result.Client_Tranx_Id = nnce;

    result.id = $scope.afterSaleSegs.id;
    result.locator = $scope.afterSaleSegs.locator;
    result.currency = $scope.currency;

    result.s3_first_passenger_email = userid;

    $scope.milesUsed = 0;

    for (const seg of $scope.Booking.tnr.tnr_segments) {
      if (seg.segt_class.substring(0, 1) == 'A') {
        $scope.milesUsed += seg.products.filter(prod => !prod.cancelled && !prod.provisional).length * 2;
      } else if (seg.segt_class.substring(0, 1) == 'H') {
        $scope.milesUsed += seg.products.filter(prod => !prod.cancelled && !prod.provisional).length * 2;
      } else if (seg.segt_class.substring(0, 1) == 'B') {
        $scope.milesUsed += seg.products.filter(prod => !prod.cancelled && !prod.provisional).length * 1;
      }
    }

    result.miles_used = $scope.milesUsed - $scope.milesToBeRefunded;

    if (globalData.hasBookingCreatedInSameQuater($scope.Booking) && $scope.Booking.tnr.tnr_segments.length > 0 && $scope.Booking.tnr.tnr_tkts.length > 0) {
      result.is_in_same_quarter = true;
    }

    $scope.Booking.tnr.tnr_tkts.forEach(tkt => {
      if (tkt.tktd_status === 1 && tkt.isChecked) {
        result.s3_items_to_cancel.push(tkt.tktd_tcn);
      }
    });

    return result;
  }

  $scope.CancelSegmentsBlueVoucher = function () {

    openModal();
    var xRequest = generateCancelBlueVoucher();
    var mres1 = RESOURCES.HOST;

    afterSalesService.exchangeBooking(xRequest, mres1)
        .then(function (res) {
          //Check if there is an error.
          if (res.wsi_error) {
            if (res.wsi_error.err_ers_text) {
              $scope.exchangeError = res.wsi_error.err_ers_text;
            } else {
              $scope.exchangeError = res.wsi_error.err_wsi_text;
            }
            closeModal();
          } else {
            //if Success then process the response.
            if (res.ExchBookingServiceOut) {
	      // Update the blue voucher balance
              $rootScope.loggedInUser.ARIA_User.ESV_remaining = parseInt($rootScope.loggedInUser.ARIA_User.ESV_remaining) + $scope.blueVouchersToBeRefunded;

              res.ExchBookingServiceOut = s3_ProcessPaxInfo(res.ExchBookingServiceOut);
              globalData.setElgBkgData(res.ExchBookingServiceOut);
	      
              if (res.ExchBookingServiceOut.tnr.tnr_no_itin || res.ExchBookingServiceOut.tnr.tnr_segments.length === 0) {
                $location.path('/home');
              } else {
                globalData.setRefCode(xRequest.locator);
                $location.path('/retrieve');
              }
            }
            closeModal(); //Close the busy indicator.
          }
        }, function (err) {
        });
  };

  function generateCancelBlueVoucher() {
    var result = {
      id: '',
      locator: '',
      isBlue: $scope.isBlue,
      can_segs: [],
      s3_items_to_cancel:[],
    };

    var nnce = nonceGenerator();
    var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
    var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

    result.Aria_User_Name = userid;
    result.Aria_Passcode = rndString;
    result.Client_Tranx_Id = nnce;

    result.id = $scope.afterSaleSegs.id;
    result.locator = $scope.afterSaleSegs.locator;
    result.currency = $scope.currency;

    result.s3_first_passenger_email = userid;

    $scope.Booking.tnr.tnr_tkts.forEach(tkt => {
      if (tkt.tktd_status === 1 && tkt.tktd_can_be_cancelled) {
        result.s3_items_to_cancel.push(tkt.tktd_tcn);
      }
    });

    return result;
  }

  function getSSR(response) {
    var objTfax = {
      segno: '',
      code: '',
      pax: ''
    };
    var ssr = [];
    var paxNum;

    if (response.seats) {
      for (var i = 0; i < response.seats.length; i++) {
        for (var j = 0; j < response.seats[i].seats_placements.length; j++) {
          if (response.seats[i].seats_placements[j].placement_meal_code) {
            objTfax = {
              segno: '',
              code: '',
              pax: ''
            };
            objTfax.segno = response.seats[i].seats_seg_num;
            var pax_name = response.seats[i].seats_placements[j].placement_pax_name.split('/');
            pax_name[1] = pax_name[1].replace(/-M/g, '');
            paxNum = '';
            paxNum = getPaxNum(pax_name[1], pax_name[0], response);
            //console.log(paxNum);
            objTfax.pax = paxNum;
            objTfax.code = response.seats[i].seats_placements[j].placement_meal_code;
            ssr.push(objTfax);
          }
        }
      }
    }
    if (ssr.length > 0) {
      var ssr1 = process2(ssr);
      //console.log(ssr1);
      var ssr2 = process3(ssr1);
      //console.log("FINAL:")
      //console.log(ssr2);
      $scope.ssr = ssr2;
    } else {
      return ssr;
    }
  }

  function process3(data) {
    var tfax_temp = {
      code: '',
      paxnums: []
    };
    var paxs = [];
    var found1 = false;
    var found2 = false;
    var i = 0;
    var j = 0;

    for (i = 0; i < data.length; i++) {
      data[i].tfax_new = [];

      for (j = 0; j < data[i].tfax.length; j++) {
        if (data[i].tfax_new.length == 0) {
          tfax_temp.code = data[i].tfax[j].code;
          tfax_temp.paxnums.push(data[i].tfax[j].pax);

          data[i].tfax_new.push(tfax_temp);
        } else {

          var tfax_temp = {
            code: '',
            paxnums: []
          };
          found1 = false;

          for (var k = 0; k < data[i].tfax_new.length; k++) {
            if (data[i].tfax_new[k].code == data[i].tfax[j].code) {
              found1 = true;
              found2 = false;

              for (var l = 0; l < data[i].tfax_new[k].paxnums.length; l++) {
                if (data[i].tfax_new[k].paxnums[l] == data[i].tfax[j].pax) {
                  found2 = true;
                  break;
                }
              }
              if (found2 == false) {
                data[i].tfax_new[k].paxnums.push(data[i].tfax[j].pax);
              }
              break;
            }
          }

          if (found1 == false) {
            tfax_temp = {
              code: '',
              paxnums: []
            };
            tfax_temp.code = data[i].tfax[j].code;
            tfax_temp.paxnums.push(data[i].tfax[j].pax);
            data[i].tfax_new.push(tfax_temp);
          }
        }
      }
    }
    return data;
  }

  function process2(data) {
    var objTfax = {
      segno: '',
      tfax: []
    };
    var temp = {
      code: '',
      pax: ''
    };
    var result = [];

    for (var i = 0; i < data.length; i++) {
      if (i == 0) {
        objTfax.segno = data[i].segno;
        temp.code = data[i].code;
        temp.pax = data[i].pax;

        objTfax.tfax.push(temp);
        result.push(objTfax);
      } else {
        var x = false;
        for (var j = 0; j < result.length; j++) {
          if (result[j].segno == data[i].segno) {

            temp = {
              code: '',
              pax: ''
            };

            //console.log("result");
            temp.code = data[i].code;
            temp.pax = data[i].pax;
            result[j].tfax.push(temp);
            x = true;
            break;
          }
        }

        if (x == false) {
          var objTfax = {
            segno: '',
            tfax: []
          };
          objTfax.segno = data[i].segno;

          temp = {
            code: '',
            pax: ''
          };
          temp.code = data[i].code;
          temp.pax = data[i].pax;
          objTfax.tfax.push(temp);
          result.push(objTfax);
        }
      }
    }
    return result;
  }

  function getPaxNum(fname, sname, response) {
    var result = 0;
    for (var i = 0; i < response.tnr.tnr_names.length; i++) {
      if ((response.tnr.tnr_names[i].name_initials == fname) && (response.tnr.tnr_names[i].name_surname == sname)) {
        result = i + 1;
        break;
      }
    }
    return result;
  }
});
