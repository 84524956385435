import app from '../app';
import {closeModal, convertDate, convertTime, nonceGenerator, openModal, sortFares} from '../utils';

app.controller('ReturnController', function ($scope, $rootScope, $route, $location, RESOURCES, availabilityService, globalData, md5, $translate) {

    init();

    function init() {
        $scope.originList = globalData.getStaionList(globalData.getbookingType());
        $scope.destinationList = globalData.getStaionList(globalData.getbookingType());
        $scope.returnError = '';
        $scope.trains = {};
        $scope.currency = globalData.getCurrency();
        $scope.currencySign = globalData.getCurrencySign($scope.currency);
        $scope.enqData = globalData.getBookingEnquiryData();
        $scope.minDate = $scope.enqData.dDateFull;
        $scope.originCountry = $scope.enqData.origin.substr(0, 2);
        $scope.originName = $scope.enqData.returnOriginFullName;
        $scope.destinationName = $scope.enqData.returnDestinationFullName;
        $scope.today = new Date(); //current date to stop date components displaying previous dates

        $scope.isER = globalData.getbookingType() == "R";
        $scope.maxDate = new Date();
        $scope.maxDate = $scope.maxDate.setDate((new Date()).getDate() + $scope.enqData.bookingHorizon);

        if ($scope.enqData.dDateFull > $scope.enqData.returndDateFull) {
            $scope.enqData.returndDateFull = $scope.enqData.dDateFull;
            $scope.enqData.returndDate = $scope.enqData.dDate;
        }

        $scope.departDateValue = new Date($scope.enqData.returndDateFull);
        $scope.departTimeValue = new Date('00:00');
        $scope.noTrains = false;

        if ((globalData.getbookingType() == 'P') || (globalData.getbookingType() == 'T') || (globalData.getbookingType() == 'Y')) {
            $scope.today.setDate($scope.today.getDate() + 1);
        } else if (globalData.getbookingType() == 'R') {
            $scope.maxDate = new Date();
            $scope.maxDate = $scope.maxDate.setDate($scope.today.getDate() + 220);
        }

        if ($scope.enqData.paxNum == 1) {
            $translate('Text172').then(function (txtMsg) {
                $scope.paxText = txtMsg;
            });
        } else {
            $translate('Text119').then(function (txtMsg) {
                $scope.paxText = txtMsg;
            });
        }

        openModal(); //Open the busy indicator.

        var mres1 = RESOURCES.HOST;

        var nnce = nonceGenerator();
        var userid = $rootScope.loggedInUser.ARIA_User.Aria_User_Name.toLowerCase();
        var rndString = md5.createHash(userid + $rootScope.loggedInUser.ARIA_User.password + nnce);

        availabilityService.getFareAvailability(userid, rndString, nnce, $scope.enqData.booking_type, $scope.enqData.paxNum, $scope.enqData.returnOrigin, $scope.enqData.returnDestination, $scope.enqData.returndDate, mres1, $scope.currency)
            .then(function (res) {
                //Check if there is an error.
                if (res.wsi_error) {
                    $scope.trains = {};
                    if (res.wsi_error.err_ers_text) {
                        $scope.returnError = res.wsi_error.err_ers_text;
                    } else {
                        $scope.returnError = res.wsi_error.err_wsi_text;
                    }
                    closeModal();
                } else {
                    //if no error then process the response.
                    if (res.FareAvailabilityServiceOut.solutions_out) {
                        $scope.noTrains = false;
                        var bSale = globalData.getBookingSegments();
                        if (globalData.getbookingType() === 'D') {
                            switch (bSale[0].fareType.toLowerCase()) {
                                case 'eurostar standard':
                                    $scope.trains = res;
                                    filterDutyFare();
                                    break;
                                case 'standard':
                                    $scope.trains = res;
                                    filterDutyFare();
                                    break;

                                default:
                                    $scope.trains = processResponse(res);
                                    break;
                            }
                        } else {
                            $scope.trains = res;
                        }
                        filterAMSBXS();
                        filterThCarrier();
                        sortFares($scope.trains);
                    } else {
                        $scope.noTrains = true;
                    }
                    closeModal(); //Close the busy indicator.
                }
            }, function (err) {
                $translate('Text238').then(function (txtMsg) {
                    $scope.returnError = txtMsg;
                });
                closeModal(); //Close the busy indicator.
            });
    }

    $scope.onDestinationSelect = function (item) {
        var originHorizon = globalData.getStationHorizon($scope.originName);

        if (originHorizon <= item.stn_bkg_hrzn) {
            $scope.today = new Date(); //current date to stop date components displaying previous dates
            $scope.maxDate = new Date();
            $scope.maxDate = $scope.maxDate.setDate($scope.today.getDate() + originHorizon);
            $scope.enqData.bookingHorizon = originHorizon;
        } else if (item.stn_bkg_hrzn <= originHorizon) {
            $scope.today = new Date(); //current date to stop date components displaying previous dates
            $scope.maxDate = new Date();
            $scope.maxDate = $scope.maxDate.setDate($scope.today.getDate() + item.stn_bkg_hrzn);
            $scope.enqData.bookingHorizon = item.stn_bkg_hrzn;
        }
    };

    function filterAMSBXS() {
        var i = 0;
        var dataFound = false;

        while (i < $scope.trains.FareAvailabilityServiceOut.solutions_out.length) {
            dataFound = false;

            if (($scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].dep_station.toUpperCase() === 'NLAMA' &&
                    $scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].arr_station.toUpperCase() === 'BEBMI') ||
                ($scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].dep_station.toUpperCase() === 'BEBMI'
                    && $scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].arr_station.toUpperCase() === 'NLAMA')) {
                if ($scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].carrier === 'SB' ||
                    $scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].carrier === 'NS' ||
                    $scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].carrier === 'TH') {
                    dataFound = true;
                }
            }

            if (dataFound === true) {
                $scope.trains.FareAvailabilityServiceOut.solutions_out.splice(i, 1);
            } else {
                i = i + 1;
            }
        }
        if ($scope.trains.FareAvailabilityServiceOut.solutions_out.length === 0) {
            $scope.noTrains = true;
        }
    }

    function filterThCarrier() {
        var i = 0;
        var dataFound = false;
        while (i < $scope.trains.FareAvailabilityServiceOut.solutions_out.length) {
            dataFound = false;
            if ($scope.trains.FareAvailabilityServiceOut.solutions_out[i].legs[0].carrier === 'TH') {
                dataFound = true;
            }

            if (dataFound === true) {
                $scope.trains.FareAvailabilityServiceOut.solutions_out.splice(i, 1);
            } else {
                i = i + 1;
            }
        }
        if ($scope.trains.FareAvailabilityServiceOut.solutions_out.length === 0) {
            $scope.noTrains = true;
        }
    }

    function filterDutyFare() {
        if (globalData.getbookingType() == 'D') {
            $scope.trains.FareAvailabilityServiceOut.solutions_out.forEach(train => {
                if (parseInt(train.legs[0].dep_time) > parseInt('0800') &&
                    parseInt(train.legs[0].arr_time) < parseInt('2000')) {

                    let fares = train.fares.filter(fare =>
                        (fare.type.toLowerCase() === 'eurostar standard' || fare.type.toLowerCase() === 'eurostar plus' || fare.type.toLowerCase() === 'standard' || fare.type.toLowerCase() === 'standard premier')
                    );

                    train.fares = fares;
                }
            });
        }
    }

    $scope.checkSameViaPoint = function (item) {
        var result = true;
        var sDate1 = '';
        var sTime1 = '';
        var sDate2 = '';
        var sTime2 = '';
        var hrs = undefined;
        var bSale = globalData.getBookingSegments();

        // Outward is direct
        if (bSale.length == 1) {
            sDate1 = $scope.enqData.dDateFull;
            sTime1 = bSale[0].arr_time_full.substring(0, 2) + ':' + bSale[0].arr_time_full.substring(2, 4);
        }
        // Outward is indirect
        else {
            sDate1 = $scope.enqData.dDateFull;
            sTime1 = bSale[1].arr_time_full.substring(0, 2) + ':' + bSale[1].arr_time_full.substring(2, 4);
        }

        sDate2 = $scope.enqData.returndDateFull;
        sTime2 = item.legs[0].dep_time.substring(0, 2) + ':' + item.legs[0].dep_time.substring(0, 2);

        // Find out number of hours between start of return journey and end
        // of outward journey
        hrs = globalData.calcDateDiffInHrs(sDate1, sTime1, sDate2, sTime2);

        if (hrs < 0) {
            result = false;
        } else if (hrs < 24) {
            if ((bSale.length == 1) && (item.legs.length > 1)) {
                result = false;
            } else if ((bSale.length > 1) && (item.legs.length == 1)) {
                result = false;
            } else if ((bSale.length > 1) && (item.legs.length > 1)) {
                if (bSale[0].destination != item.legs[1].dep_station) {
                    result = false;
                }
            }
        }

        return result;
    };
    //filter destination station on return for the country of origin only.

    $scope.filteredList = function () {
        return $scope.destinationList.filter(function (stn) {
            return $scope.originCountry.indexOf(stn.stn_code.substr(0, 2)) !== -1;
        });
    };

    function processResponse(resp) {
        var result = {};
        resp.FareAvailabilityServiceOut.solutions_out.forEach(train => {
            let fares = train.fares.filter(fare => (['eurostar standard', 'standard'].includes(fare.type.toLowerCase())));
            train.fares = fares;
        });
        resp.FareAvailabilityServiceOut.solutions_out.forEach((train, index, object) => {
            if (train.fares.length === 0) {
                object.splice(index);
            }
        });
        result = resp;
        return result;
    }

    $scope.startsWith = function (city, viewValue) {
        return city.substr(0, viewValue.length).toLowerCase() == viewValue.toLowerCase();
    };

    $scope.search = function () {
        var dataCheck = false;

        if (globalData.getStationCode($scope.destinationName) == '') {
            noResults = true;
            dataCheck = true;
        }

        if (dataCheck == false) {
            $scope.enqData.returnDestination = globalData.getStationCode($scope.destinationName);
            $scope.enqData.returnDestinationNumeric = globalData.getStationCodeNumeric($scope.destinationName);
            $scope.enqData.returnDestinationFullName = $scope.destinationName;
            $scope.enqData.returndDate = convertDate($scope.departDateValue);
            $scope.enqData.returndDateFull = $scope.departDateValue;
            $scope.enqData.returndTime = convertTime($scope.departTimeValue);
            globalData.setBookingEnquiryData($scope.enqData);
            $route.reload();
        }
    };

    $scope.readKey = function (event) {
        var x = event.which || event.keyCode;
        //block all keypress except tab.
        if (x != 9) {
            event.preventDefault();
        }
    };

    $scope.getMilesNeeded = function (oTrain, cCos) {
        const oBookingSale = [];

        for (var iCount = 0; iCount <= oTrain.legs.length - 1; iCount++) {
            var oSegments = {};
            oSegments.s3_items = [];

            for (var jCount = 0; jCount <= oTrain.fares.length - 1; jCount++) {
                if (cCos == oTrain.fares[jCount].type) {
                    oSegments.fareType = oTrain.fares[jCount].type;

                    $scope.trains.FareAvailabilityServiceOut.s3_passengers.forEach(passenger => {
                        oTrain.fares[jCount].code.forEach(tCode => {
                            let item = {passengerId: '', tariffCode: ''};
                            item.passengerId = passenger.id;
                            item.tariffCode = tCode.tariffCode;
                            oSegments.s3_items.push(item);
                        });
                    });
                }
            }

            oBookingSale.push(oSegments);
        }

      const stdFareTypes = ['Standard', 'Eurostar Standard'];
        let usedMiles = 0;

        oBookingSale.forEach(seg => {
          usedMiles += seg.s3_items.length * ((stdFareTypes.includes(seg.fareType)) ? 1 : 2);
        });

        return `${usedMiles} miles(s)`;
    }

    $scope.goNext = function (oTrain, cCos) {

        var iCount = 0;

        var oBookingSale = globalData.getBookingSegments();
        var bReq = globalData.getBookingEnquiryData();
        bReq.returnFareType = cCos;
        globalData.setBookingEnquiryData(bReq);
        while (iCount < oBookingSale.length) {
            if (oBookingSale[iCount].legType == 'return') {
                oBookingSale.splice(iCount, 1);
            } else {
                iCount++;
            }
        }

        for (iCount = 0; iCount < oTrain.legs.length; iCount++) {
            var returnSeg = {};
            returnSeg.origin = oTrain.legs[iCount].dep_station;
            returnSeg.originFullName = globalData.getStationName(oTrain.legs[iCount].dep_station); //oTrain.legs[iCount].dep_station_name;
            returnSeg.destination = oTrain.legs[iCount].arr_station;
            returnSeg.destinationFullName = globalData.getStationName(oTrain.legs[iCount].arr_station); //oTrain.legs[iCount].arr_station_name;

            returnSeg.carrier = oTrain.legs[iCount].carrier;
            returnSeg.dep_date = $scope.enqData.returndDate;
            returnSeg.dep_date_full = $scope.enqData.returndDateFull;
            returnSeg.dep_time = oTrain.legs[iCount].dep_time;
            returnSeg.dep_time_full = oTrain.legs[iCount].dep_time; // use filter while displaying full date
            returnSeg.arr_time_full = oTrain.legs[iCount].arr_time;
            returnSeg.JourneyTime = oTrain.legs[iCount].duration;
            returnSeg.train_number = oTrain.legs[iCount].train_number;
            returnSeg.equipment_code = oTrain.legs[iCount].equipment_code;
            returnSeg.reservation = oTrain.legs[iCount].reservation;

            if ($scope.enqData.returnOrigin === 'BEABS' || $scope.enqData.returnDestination === 'BEABS') {
                returnSeg.isABS = true;
            } else {
                returnSeg.isABS = false;
            }

            returnSeg.legType = 'return';

            returnSeg.s3_direction = 'inbound';
            returnSeg.s3_service_identifier = oTrain.legs[iCount].s3_service_identifier;
            returnSeg.s3_items = [];

            for (var jCount = 0; jCount <= oTrain.fares.length - 1; jCount++) {
                if (cCos == oTrain.fares[jCount].type) {
                    returnSeg.ccos = oTrain.fares[jCount].ccos[iCount];
                    returnSeg.fare = (iCount == 0) ? oTrain.fares[jCount].price : 0;
                    returnSeg.fareType = oTrain.fares[jCount].type;
                    returnSeg.paxType = oTrain.fares[jCount].paxtype;
                    returnSeg.fbc = oTrain.fares[jCount].code[0].tariffCode;

                    // returnSeg.s3_items = oTrain.fares[jCount].code.map(tCode => {
                    //   let item = {passengerId : "", tariffCode : ""};
                    //   item.passengerId = tCode.passengerId;
                    //   item.tariffCode = tCode.tariffCode;
                    //   item.price = tCode.price;
                    //
                    //   return item;
                    // });
                    $scope.trains.FareAvailabilityServiceOut.s3_passengers.forEach(passenger => {
                        oTrain.fares[jCount].code.forEach(tCode => {
                            let item = {passengerId: '', tariffCode: ''};
                            item.passengerId = passenger.id;
                            item.tariffCode = tCode.tariffCode;
                            returnSeg.s3_items.push(item);
                        });
                    });

                    // returnSeg.s3_items = $scope.trains.FareAvailabilityServiceOut.s3_passengers.map(passenger => {
                    //     let item = {passengerId : "", tariffCode : ""};
                    //     item.passengerId = passenger.id;
                    //     item.tariffCodes = oTrain.fares[jCount].code;
                    //     return item;
                    //   });
                }
            }
            // console.log("returnSeg = ", returnSeg);
            oBookingSale.push(returnSeg);
        }

      const stdFareTypes = ['Standard', 'Eurostar Standard'];
        let usedMiles = 0;

        oBookingSale.forEach(seg => {
          usedMiles += seg.s3_items.length * ((stdFareTypes.includes(seg.fareType)) ? 1 : 2);
        });

        let tsvRemaining = 0;

        if (typeof ($rootScope.loggedInUser.ARIA_User.TSV_Balance) !== "undefined") {
            tsvRemaining = parseInt($rootScope.loggedInUser.ARIA_User.TSV_Balance);
        }

        if (globalData.getbookingType() === "R" && tsvRemaining < usedMiles) {
            $scope.notEnoughMiles = true;
            $translate('Text407').then(function (txtMsg) {
                $scope.notEnoughMilesErrMsg = txtMsg; //"There are insufficient vouchers to make this booking.";
            });

            return;
        }

        globalData.setBookingSegments(oBookingSale);

        $scope.trains = {};
        $location.path('/bookingSummary');
    };

    $scope.incDate = function (data) {
        if (data == -1) {
            globalData.decEnquiryDate(true, $scope.today);
        } else {
            globalData.incEnquiryDate(true, $scope.maxDate);
        }
        $scope.departDateValue = $scope.enqData.returndDateFull;
        $scope.search();
    };
});
